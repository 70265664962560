import { useMutation } from "react-query"

import { BACKEND_URL } from "../config"

interface useUserDataApiProps {
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useUserDataApi(props: useUserDataApiProps) {
    const getUserData = useMutation({
        mutationFn: ({ token }: { token: string }) =>
            fetch(`${BACKEND_URL}/api/users/whoami/`, {
                headers: { Authorization: token },
            }).then((res) => {
                if (res.status !== 200) {
                    throw res.statusText
                }
                return res.json()
            }),
        onSuccess: (data) => props.onSuccess && props.onSuccess(data),
        onError: (err) => props.onError && props.onError(err),
    })

    return {
        getUserData,
    }
}
