import { API_MAP } from "../../app/constants"
import { LiveManager } from "../LiveManager"
import GenericTable from "./GenericTable"

interface StartListProps {
    liveManager: LiveManager
}

export default function StartList({ liveManager }: StartListProps) {
    return (
        <>
            <GenericTable
                tableId={"start-list"}
                rowId={"athlete_id"}
                headersMap={{
                    order_number: API_MAP.order_number,
                    bib: API_MAP.bib,
                    name: API_MAP.name,
                    stance: API_MAP.stance,
                }}
                rows={liveManager.getStartList()}
            />
        </>
    )
}
