import { useState } from "react"

import { Tab, Tabs } from "@mui/material"

import { LiveManager } from "../LiveManager"
import RankingList from "../components/RankingList"
import StartList from "../components/StartList"

export default function HeadSummaries({
    liveManager,
    scores,
    trickId,
}: {
    liveManager: LiveManager
    scores: { [key: string]: number }
    trickId: string
}) {
    const [summaryIndex, setSummaryIndex] = useState(1)

    const summaries = ["Start List", "Ranking"]

    return (
        <>
            <>
                <Tabs
                    value={summaryIndex}
                    onChange={(event, newValue) => setSummaryIndex(newValue)}
                    sx={{ mb: 1 }}
                >
                    {summaries.map((s, i) => (
                        <Tab label={s} key={i} />
                    ))}
                </Tabs>
                {summaryIndex === 0 && <StartList liveManager={liveManager} />}
                {summaryIndex === 1 && (
                    <RankingList
                        liveManager={liveManager}
                        scores={scores}
                        trickId={trickId}
                    />
                )}
            </>
        </>
    )
}
