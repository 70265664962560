import React, { createContext } from "react"

import { Alert, Snackbar, SnackbarOrigin } from "@mui/material"

export interface SnackbarState extends SnackbarOrigin {
    open: boolean
    text: string
    severity: string
}

export default function useNotification() {
    const defaultSnackbarState = {
        open: false,
        text: "",
        vertical: "top",
        horizontal: "center",
        severity: "info",
    }
    const [snackbarDetails, setSnackbarDetails] = React.useState<SnackbarState>(
        {
            open: false,
            text: "",
            vertical: "top",
            horizontal: "center",
            severity: "info",
        }
    )

    const updateNotification = (fields: any) => {
        setSnackbarDetails({ ...defaultSnackbarState, ...fields })
    }

    const Notification = () => (
        <Snackbar
            open={snackbarDetails.open}
            autoHideDuration={3000}
            onClose={() =>
                setSnackbarDetails({ ...snackbarDetails, open: false })
            }
            // @ts-ignore
            anchorOrigin={{
                vertical: snackbarDetails.vertical,
                horizontal: snackbarDetails.horizontal,
            }}
        >
            <Alert
                onClose={() =>
                    setSnackbarDetails({ ...snackbarDetails, open: false })
                }
                // @ts-ignore
                severity={snackbarDetails.severity}
                sx={{ width: "100%" }}
            >
                {snackbarDetails.text}
            </Alert>
        </Snackbar>
    )

    return {
        Notification,
        updateNotification,
    }
}

export const NotificationContext = createContext<
    { updateNotification: (fields: any) => void } | undefined
>(undefined)
