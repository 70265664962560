import React, { useEffect } from "react"

import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"

import useAuth from "../hooks/useAuth"
import LoadingLayout from "../layouts/LoadingLayout"
import TopBar from "../layouts/components/TopBar"

export default function LoginPage() {
    const { user } = useAuthenticator((context) => [context.user])
    const { handleSignIn, isLoading } = useAuth()

    useEffect(() => {
        if (user) handleSignIn(user)
    }, [user])

    if (isLoading) return <LoadingLayout />

    return (
        <>
            <TopBar />
            <div style={{ marginTop: "30px" }}>
                <Authenticator
                    hideSignUp
                    components={{
                        SignIn: {
                            Footer() {
                                return <></>
                            },
                        },
                    }}
                ></Authenticator>
            </div>
        </>
    )
}
