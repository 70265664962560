import React from "react"

import { Backdrop, CircularProgress } from "@mui/material"

export default function LoadingLayout() {
    return (
        <Backdrop open={true} style={{ zIndex: 9999 }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}
