import { forwardRef, ReactElement, Ref, useState } from "react"

import CloseIcon from "@mui/icons-material/Close"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Slide,
} from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function useConfirmDialog() {
    const [confirmation, setConfirmation] = useState<
        | { title: string; content: JSX.Element; onConfirm: () => void }
        | undefined
    >(undefined)
    const [open, setOpen] = useState<boolean>(false)
    const handleClose = () => {
        setOpen(false)
    }
    const confirm = (
        title: string,
        content: JSX.Element,
        onConfirm: () => void
    ) => {
        setConfirmation({ title, content, onConfirm })
        setOpen(true)
    }

    const ConfirmDialog = () => (
        <>
            {confirmation && (
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                >
                    <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
                        {confirmation.title}
                        <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            {confirmation.content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ px: "24px" }}>
                        <Button onClick={() => setOpen(false)} color="error">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                setOpen(false)
                                confirmation.onConfirm()
                            }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )

    return { ConfirmDialog, confirm }
}
