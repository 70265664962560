import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"

import CircleIcon from "@mui/icons-material/Circle"
import HomeIcon from "@mui/icons-material/Home"
import LogoutIcon from "@mui/icons-material/Logout"
import {
    AppBar,
    Container,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material"

import useAuth from "../../hooks/useAuth"
import { UserDataContext } from "../../pages/app/UserDataProvider"
import { WebSocketContext } from "../../pages/app/WebSocketProvider"

function Restricted() {
    const { userData, judgePosition } = useContext(UserDataContext)!
    const { connectionStatus } = useContext(WebSocketContext)!

    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))

    const { handleSignOut } = useAuth()

    return (
        <Typography align={"right"} m={1} variant={"body2"}>
            <strong>{judgePosition && `J${judgePosition}:  `}</strong>
            {connectionStatus === undefined && <CircleIcon sx={{ mr: 1 }} />}
            {connectionStatus && connectionStatus === "Open" && (
                <CircleIcon
                    sx={{ mr: { xs: 0.5, md: 1 }, color: "yellowgreen" }}
                />
            )}
            {connectionStatus && connectionStatus !== "Open" && (
                <CircleIcon sx={{ mr: { xs: 0.5, md: 1 }, color: "#FF474C" }} />
            )}
            {`${userData?.first_name[0]}. ${isSmallScreen ? `${userData?.last_name.slice(0, 10)}...` : userData?.last_name}`}
            <IconButton onClick={handleSignOut}>
                <LogoutIcon sx={{ color: "white", ml: { xs: 0.5, md: 1 } }} />
            </IconButton>
        </Typography>
    )
}

export default function TopBar() {
    const navigate = useNavigate()
    const userData = useContext(UserDataContext)

    return (
        <AppBar
            position="sticky"
            color="secondary"
            sx={{ backgroundColor: "#222" }}
        >
            <Container maxWidth={false} sx={{ px: { xs: "5px", md: 0 } }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item xs={3} md={4}>
                        <IconButton
                            sx={{ ml: "auto", color: "white" }}
                            onClick={() => navigate("/")}
                        >
                            <HomeIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={0} md={4}>
                        <Typography
                            align={"center"}
                            m={{ md: 1 }}
                            variant={"body1"}
                            sx={{ display: { xs: "none", md: "block" } }}
                        >
                            <strong>StatBoard</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={9} md={4}>
                        {userData && <Restricted />}
                    </Grid>
                </Grid>
            </Container>
        </AppBar>
    )
}
