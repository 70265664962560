import { Fragment, useContext } from "react"
import { useParams } from "react-router-dom"

import {
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    Switch,
    Typography,
} from "@mui/material"

import useCandidateUpdateApi from "../../../api/app/useCandidateUpdateApi"
import useCandidatesApi from "../../../api/app/useCandidatesApi"
import { BACKEND_URL } from "../../../config"
import useDialogForm from "../../../hooks/useDialogForm"
import useExcel from "../../../hooks/useExcel"
import { NotificationContext } from "../../../hooks/useNotification"
import LoadingLayout from "../../../layouts/LoadingLayout"
import GenericTable from "../../live/components/GenericTable"
import { API_MAP } from "../constants"
import BackButton from "../master/components/BackButton"

export default function QualifiedListPage({ initial }: { initial: boolean }) {
    const { competitionId } = useParams()
    const { updateNotification } = useContext(NotificationContext)!

    const { updateCandidate } = useCandidateUpdateApi({
        onError: (error) => {
            updateNotification({
                open: true,
                severity: "error",
                text: error?.toString(),
            })
        },
        onSuccess: (data) => {
            updateNotification({
                open: true,
                severity: "success",
                text: "Done.",
            })
            setTimeout(() => {
                window.location.reload()
            }, 700)
        },
    })

    const { downloadExcel } = useExcel()
    const { DialogForm, setOpen } = useDialogForm({
        name: "Upload candidates",
        endpoint: `${BACKEND_URL}/views/events/competitions/${competitionId}/candidates-upload/`,
        onFailure: (error) => {
            updateNotification({
                open: true,
                severity: "error",
                text: error?.toString(),
            })
        },
        onSuccess: (data) => {
            updateNotification({
                open: true,
                severity: "success",
                text: "Qualified list has been uploaded.",
            })
            setTimeout(() => {
                window.location.reload()
            }, 700)
        },
    })
    const { isCandidatesLoading, candidatesError, candidates } =
        useCandidatesApi({
            competitionId: competitionId!,
            enabled: Boolean(competitionId),
        })

    const candidatesHeaders = {
        order_number: API_MAP.order_number,
        fis_code: API_MAP.fis_code,
        bib: API_MAP.bib,
        name: API_MAP.name,
        nsa_code: API_MAP.nsa_code,
        yb: API_MAP.yb,
        stance: API_MAP.stance,
        enters: "Enters",
    }

    const getRows = () => {
        let newCandidates = candidates?.map((candidate) =>
            Object.assign(candidate, {
                enters: (
                    <Fragment>
                        <Switch
                            // @ts-ignore
                            size={"small"}
                            checked={candidate.enter}
                            onChange={() => {
                                updateCandidate.mutate({
                                    candidateId: candidate.id,
                                    enter: !candidate.enter,
                                })
                            }}
                        />
                    </Fragment>
                ),
            })
        )
        return newCandidates!
    }
    if (isCandidatesLoading) return <LoadingLayout />

    if (candidatesError) return "An error has occurred: " + candidatesError

    return (
        <>
            <DialogForm />
            <Grid container>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <BackButton />
                    <Paper
                        component={Stack}
                        direction="column"
                        justifyContent="center"
                        sx={{ boxShadow: "none" }}
                    >
                        <Box sx={{ position: "absolute" }}>
                            {initial && (
                                <Button
                                    variant={"outlined"}
                                    onClick={() => setOpen(true)}
                                    size={"small"}
                                >
                                    Upload
                                </Button>
                            )}

                            {Array.isArray(candidates) &&
                                candidates.length > 0 && (
                                    <Button
                                        variant={"outlined"}
                                        onClick={() =>
                                            downloadExcel(
                                                "qualified-list",
                                                candidates
                                                    .filter(
                                                        (c) =>
                                                            // @ts-ignore
                                                            c.enter === true
                                                    )
                                                    .map((c, index) =>
                                                        Object.assign(c, {
                                                            order_number:
                                                                index + 1,
                                                        })
                                                    ),
                                                {
                                                    order_number:
                                                        API_MAP.order_number,
                                                    fis_code: API_MAP.fis_code,
                                                    bib: API_MAP.bib,
                                                    name: API_MAP.name,
                                                    nsa_code: API_MAP.nsa_code,
                                                    yb: API_MAP.yb,
                                                    stance: API_MAP.stance,
                                                }
                                            )
                                        }
                                        size={"small"}
                                        sx={{ ml: 1 }}
                                    >
                                        Download
                                    </Button>
                                )}
                        </Box>
                        <Typography variant={"h5"} align={"center"}>
                            Qualified List
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {Array.isArray(candidates) && candidates.length > 0 ? (
                        <GenericTable
                            tableId={"start-list"}
                            rowId={"id"}
                            headersMap={candidatesHeaders}
                            rows={getRows()}
                        />
                    ) : (
                        <>
                            <Typography align={"center"}>
                                No athletes
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    )
}
