import { BACKEND_URL } from "../../config"
import useApi from "./useApi"

export interface Run {
    id: string
    bib: number
    name: string
    nsa_code: string
    order_number: number
    round_number: number
    stance: string
    score:
        | undefined
        | {
              score: string
          }
}

interface useRunsApiProps {
    stageId: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useRunsApi(props: useRunsApiProps) {
    const {
        isLoading: isRunsLoading,
        refetch: refetchRuns,
        error: runsError,
        data: runs,
    } = useApi<Run[]>({
        url:
            `${BACKEND_URL}/api/events/runs/?` +
            new URLSearchParams({ stage_id: props.stageId }),
        enabled: props.enabled,
        onSuccess: props.onSuccess,
        onError: props.onError,
    })

    return {
        isRunsLoading,
        refetchRuns,
        runsError,
        runs,
    }
}
