import { useContext } from "react"

import { BACKEND_URL } from "../../config"
import { NotificationContext } from "../../hooks/useNotification"
import useApi from "./useApi"

interface Stage {
    id: string
    date: string
    name: string
    format: string
    rounds: { id: string }[]
    athlets_for_next_stage: number
    additional_format_settings: any
}

interface Competition {
    id: string
    name: string
    rank: string
    sport: string
    stages: Stage[]
    category: string
    discipline: string
}

interface Event {
    competition: Competition
    event_id: string
    event_name: string
    is_head: boolean
}

interface useEventApiProps {
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useEventsApi(props: useEventApiProps) {
    const { updateNotification } = useContext(NotificationContext)!
    const {
        isLoading: isEventsLoading,
        error: eventsError,
        refetch: refetchEvents,
        data: events,
    } = useApi<Event[]>({
        url: `${BACKEND_URL}/api/events/`,
        enabled: props.enabled,
        onSuccess: (data) => props.onSuccess && props.onSuccess(data),
        onError: (err) => {
            updateNotification({
                open: true,
                severity: "error",
                text: err?.toString(),
            })
            props.onError && props.onError(err)
        },
    })

    return { isEventsLoading, eventsError, refetchEvents, events }
}
