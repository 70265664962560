import { useContext } from "react"
import { useMutation } from "react-query"

import { BACKEND_URL } from "../../config"
import { UserDataContext } from "../../pages/app/UserDataProvider"
import { prepareError } from "../utils"

interface useCandidateUpdateApiProps {
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useCandidateUpdateApi(
    props: useCandidateUpdateApiProps
) {
    const { token, authRedirect } = useContext(UserDataContext)!

    const updateCandidate = useMutation({
        mutationFn: ({
            candidateId,
            enter,
        }: {
            candidateId: string
            enter: boolean
        }) =>
            fetch(
                `${BACKEND_URL}/api/events/candidates/${candidateId}/update/`,
                {
                    method: "PUT",
                    headers: {
                        Authorization: token,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ enter }),
                }
            ).then((res) => {
                if (res.status === 401 || res.status === 403) {
                    authRedirect()
                } else if (!res.ok) {
                    return prepareError(res)
                }
            }),
        onSuccess: (data) => props.onSuccess && props.onSuccess(data),
        onError: (err) => props.onError && props.onError(err),
    })

    return {
        updateCandidate,
    }
}
