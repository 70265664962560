import { useContext } from "react"
import { useQuery } from "react-query"

import Papa from "papaparse"

import { BACKEND_URL } from "../../config"
import { UserDataContext } from "../../pages/app/UserDataProvider"
import { prepareError } from "../utils"

interface useRankingApiProps {
    stageId: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useRankingApi(props: useRankingApiProps) {
    const { token, authRedirect } = useContext(UserDataContext)!

    const {
        isLoading: isRankingLoading,
        refetch: refetchRanking,
        error: rankingError,
        data: ranking,
    } = useQuery<any>(
        ["ranking"],
        () =>
            // @ts-ignore
            fetch(
                `${BACKEND_URL}/api/events/stages/${props.stageId}/ranking/`,
                {
                    headers: { Authorization: token },
                }
            )
                .then((res) => {
                    if (res.status === 401 || res.status === 403) {
                        authRedirect()
                    } else if (!res.ok) {
                        return prepareError(res)
                    } else return res.json()
                })
                .then(
                    (rawData) =>
                        new Promise((resolve, reject) => {
                            Papa.parse(rawData, {
                                header: true,
                                complete: function (results) {
                                    resolve(results.data)
                                },
                                error: reject,
                            })
                        })
                )
                .then((rows: any) => {
                    for (let i = 0; i < rows.length; i++) {
                        rows[i].order_number = Number(rows[i].order_number)

                        for (const [key, value] of Object.entries(rows[i])) {
                            if (value === "") {
                                rows[i][key] = null
                            } else if (key.endsWith("_score")) {
                                if (isNaN(Number(value))) {
                                    rows[i][key] = value
                                } else {
                                    rows[i][key] = Number(value)
                                }
                            } else if (key.startsWith("final_score_")) {
                                if (isNaN(Number(value))) {
                                    rows[i][key] = value
                                } else {
                                    rows[i][key] = Number(value)
                                }
                            }
                        }
                    }
                    rows = rows.filter((row: any) => row.athlete_id)
                    return rows
                }),
        {
            refetchOnWindowFocus: false,
            enabled: props.enabled,
            onSuccess: (data) => props.onSuccess && props.onSuccess(data),
            onError: (err) => props.onError && props.onError(err),
            retry: 0,
        }
    )

    return { isRankingLoading, refetchRanking, rankingError, ranking }
}
