export const IS_PROD = process.env.NODE_ENV === "production"
export const BACKEND_URL = IS_PROD
    ? "https://api.statboard.pro"
    : "http://0.0.0.0:8000"

export const BACKEND_WS = IS_PROD
    ? "wss://ws.statboard.pro"
    : "ws://0.0.0.0:8000/socket"

export const COGNITO_URL =
    "https://judging-day-auth.auth.eu-central-1.amazoncognito.com/oauth2/authorize?client_id=6u9hlqg4jo3dkuguqjirp90elv&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fapp.statboard.pro%2Fapp-auth%2F"
