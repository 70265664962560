import { useContext } from "react"
import { useMutation } from "react-query"

import { BACKEND_URL } from "../../config"
import { NotificationContext } from "../../hooks/useNotification"
import { UserDataContext } from "../../pages/app/UserDataProvider"
import { prepareError } from "../utils"

interface useSaveJudgesApiProps {
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useSaveJudgesApi(props: useSaveJudgesApiProps) {
    const { token, authRedirect } = useContext(UserDataContext)!
    const { updateNotification } = useContext(NotificationContext)!

    const saveJudges = useMutation({
        mutationFn: (mutationProps: {
            competitionId: string
            userIds: string[]
        }) =>
            fetch(
                `${BACKEND_URL}/api/events/judges/save/?competition_id=${mutationProps.competitionId}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: token,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        user_ids: mutationProps.userIds,
                    }),
                }
            ).then((res) => {
                if (res.status === 401 || res.status === 403) {
                    authRedirect()
                } else if (!res.ok) {
                    return prepareError(res)
                }
            }),
        onSuccess: (data) => props.onSuccess && props.onSuccess(data),
        onError: (err) => {
            updateNotification({
                open: true,
                severity: "error",
                text: err?.toString(),
            })
            props.onError && props.onError(err)
        },
    })

    return {
        saveJudges,
    }
}
