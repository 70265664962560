import { useContext } from "react"

import { BACKEND_URL } from "../../config"
import { NotificationContext } from "../../hooks/useNotification"
import useApi from "./useApi"

interface ClinicExercise {
    id: string
    status: string
    competition_data: {
        name: string
        rank: string
        sport: string
        category: string
        discipline: string
    }
    stage_data: {
        date: string
        form: string
        name: string
        round_amount: string
    }
}

interface useClinicExercisesApiProps {
    clinicId: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useClinicExercisesApi(
    props: useClinicExercisesApiProps
) {
    const { updateNotification } = useContext(NotificationContext)!
    const {
        isLoading: isClinicExercisesLoading,
        error: clinicExercisesError,
        refetch: refetchClinicExercises,
        data: clinicExercises,
    } = useApi<ClinicExercise[]>({
        url: `${BACKEND_URL}/api/clinics/${props.clinicId}/clinic-exercises/`,
        enabled: props.enabled,
        onSuccess: (data) => props.onSuccess && props.onSuccess(data),
        onError: (err) => {
            updateNotification({
                open: true,
                severity: "error",
                text: err?.toString(),
            })
            props.onError && props.onError(err)
        },
    })

    return {
        isClinicExercisesLoading,
        clinicExercisesError,
        refetchClinicExercises,
        clinicExercises,
    }
}
