import { useQuery } from "react-query"

import Papa from "papaparse"

import { BACKEND_URL } from "../../config"
import { prepareError } from "../utils"

interface usePublicRankingApiProps {
    competitionId: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function usePublicRankingApi(props: usePublicRankingApiProps) {
    const getRanking = async () => {
        const rankingUrl = await fetch(
            `${BACKEND_URL}/public/api/events/competitions/${props.competitionId}/ranking_url/`
        ).then((res) => res.json())
        return fetch(rankingUrl)
    }
    const {
        isLoading: isPublicRankingLoading,
        refetch: refetchPublicRanking,
        error: publicRankingError,
        data: publicRanking,
    } = useQuery<any>(
        ["public-ranking"],
        () =>
            // @ts-ignore
            getRanking()
                .then((res) => {
                    if (!res.ok) {
                        return prepareError(res)
                    } else return res.text()
                })
                .then(
                    (rawData) =>
                        new Promise((resolve, reject) => {
                            Papa.parse(rawData, {
                                header: true,
                                complete: function (results) {
                                    resolve(results.data)
                                },
                                error: reject,
                            })
                        })
                )
                .then((rows: any) => {
                    for (let i = 0; i < rows.length; i++) {
                        rows[i].order_number = Number(rows[i].order_number)

                        for (const [key, value] of Object.entries(rows[i])) {
                            if (value === "") {
                                rows[i][key] = null
                            } else if (key.endsWith("_score")) {
                                if (isNaN(Number(value))) {
                                    rows[i][key] = value
                                } else {
                                    rows[i][key] = Number(value)
                                }
                            } else if (key.startsWith("final_score_")) {
                                if (isNaN(Number(value))) {
                                    rows[i][key] = value
                                } else {
                                    rows[i][key] = Number(value)
                                }
                            }
                        }
                    }
                    rows = rows.filter((row: any) => row.athlete_id)
                    return rows
                }),
        {
            refetchOnWindowFocus: false,
            enabled: props.enabled,
            onSuccess: (data) => props.onSuccess && props.onSuccess(data),
            onError: (err) => props.onError && props.onError(err),
            retry: 0,
        }
    )

    return {
        isPublicRankingLoading,
        refetchPublicRanking,
        publicRankingError,
        publicRanking,
    }
}
