import React, { createContext, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import useAddCustomScoreApi from "../../api/app/useAddCustomScoreApi"
import useAddScoreApi from "../../api/app/useAddScoreApi"
import useCompetitionApi, { Competition } from "../../api/app/useCompetitionApi"
import useCurrentStageApi, { Stage } from "../../api/app/useCurrentStageApi"
import useNextRunApi from "../../api/app/useNextRunApi"
import useRankingApi from "../../api/app/useRankingApi"
import { Run } from "../../api/app/useRunsApi"
import { NotificationContext } from "../../hooks/useNotification"
import LoadingLayout from "../../layouts/LoadingLayout"
import { UserDataContext } from "../app/UserDataProvider"
import {
    MessageTypes,
    Namespaces,
    WebSocketContext,
} from "../app/WebSocketProvider"
import { LiveManager } from "./LiveManager"
import { LiveContextType } from "./LiveStage.types"
import HeadJudgeLiveCompetition from "./head/HeadJudgeLiveCompetition"
import IndividualJudgeLiveCompetition from "./individual/IndividualJudgeLiveCompetition"
import IndividualJudgeLiveWaiting from "./individual/IndividualJudgeLiveWaiting"

export const LiveContext = createContext<LiveContextType | undefined>(undefined)

export default function LiveCompetitionWrapper() {
    const [competition, setCompetition] = useState<Competition | undefined>(
        undefined
    )
    const [stage, setStage] = useState<Stage | undefined>(undefined)
    const [ranking, setRanking] = useState<any | undefined>(undefined)
    const [run, setRun] = useState<Run | undefined>(undefined)

    const { competitionId } = useParams()

    const { token, userData } = useContext(UserDataContext)!

    const { updateNotification } = useContext(NotificationContext)!

    const { sendMessage } = useContext(WebSocketContext)!

    const { competitionError, isCompetitionLoading } = useCompetitionApi({
        competitionId: competitionId!,
        enabled: Boolean(competitionId),
        onSuccess: (c) => setCompetition(c as Competition),
    })

    const { isStageLoading, stageError, refetchStage } = useCurrentStageApi({
        competitionId: competition?.id!,
        enabled: Boolean(competition),
        onSuccess: (s) => setStage(s as Stage),
    })

    const { refetchNextRun } = useNextRunApi({
        stageId: stage?.id!,
        enabled: Boolean(stage),
        onSuccess: (r) => setRun(r as Run),
    })

    const { isRankingLoading, refetchRanking, rankingError } = useRankingApi({
        stageId: stage?.id!,
        enabled: Boolean(stage),
        onSuccess: (r) => setRanking(r as any),
    })

    const { addScore } = useAddScoreApi({
        onError: (error) => {
            updateNotification({
                open: true,
                severity: "error",
                text: error?.toString(),
            })
        },
        onSuccess: (data) => {
            updateNotification({
                open: true,
                severity: "success",
                text: "Score added.",
            })
        },
    })

    const { addCustomScore } = useAddCustomScoreApi({})

    const [signal, setSignal] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (signal) {
            if (signal === "scored") {
                refetchRanking()
                refetchNextRun()
                sendMessage(
                    JSON.stringify({
                        type: MessageTypes.Any,
                        channel_type: Namespaces.Competition,
                        instance_id: competitionId,
                        message: {
                            type: "switch_athlete",
                        },
                    })
                )
            } else if (signal === "reload") {
                refetchStage()
                if (stage) {
                    refetchRanking()
                    refetchNextRun()
                }
            }
            setSignal(undefined)
        }
    }, [signal])

    const [liveManager, setLiveManager] = useState<LiveManager | undefined>(
        undefined
    )
    useEffect(() => {
        if (competition && stage && ranking) {
            setLiveManager(
                new LiveManager(
                    ranking,
                    competition.judges.individuals,
                    stage.form
                )
            )
        }
    }, [competition, stage, ranking])

    if (isCompetitionLoading || isStageLoading || isRankingLoading)
        return <LoadingLayout />

    if (rankingError || stageError || competitionError)
        return (
            <>
                Ranking Error: {rankingError}
                <br />
                Stage Error: {stageError}
                <br />
                Competition Error: {competitionError}
            </>
        )

    if (competition && !stage && competition.judges.head !== userData.id) {
        return (
            <IndividualJudgeLiveWaiting
                competition={competition}
                setSignal={setSignal}
            />
        )
    }

    return (
        <>
            {userData && competition && stage && ranking && liveManager && (
                <>
                    <LiveContext.Provider
                        value={{
                            // @ts-ignore
                            addScore,
                            // @ts-ignore
                            addCustomScore,
                            competition,
                            ranking,
                            run,
                            setSignal,
                            // @ts-ignore
                            stage,
                            token,
                            userData,
                            liveManager,
                        }}
                    >
                        {competition.judges.head === userData.id ? (
                            <HeadJudgeLiveCompetition />
                        ) : (
                            <IndividualJudgeLiveCompetition />
                        )}
                    </LiveContext.Provider>
                </>
            )}
        </>
    )
}
