import { useContext } from "react"

import { API_MAP } from "../../app/constants"
import { LiveManager } from "../LiveManager"
import { LiveContext } from "../LiveStageWrapper"
import GenericTable from "./GenericTable"

interface JudgeRankingListProps {
    liveManager: LiveManager
    judgeId: string
    score: any
}

export default function JudgeRankingList({
    liveManager,
    judgeId,
    score,
}: JudgeRankingListProps) {
    const { run } = useContext(LiveContext)!
    const judgeIndex = liveManager.getJudgeIndex(judgeId)

    const getHeadersMap = () => {
        let headersMap = {
            rank: API_MAP.rank,
            bib: API_MAP.bib,
            name: API_MAP.name,
        }

        for (let i = 0; i < liveManager.getRoundAmount(); i++) {
            // @ts-ignore
            headersMap[`round_${i + 1}_judge_${judgeIndex}_partial_score`] =
                `R${i + 1}`
        }

        // @ts-ignore
        headersMap[`final_score_judge_${judgeIndex}`] = API_MAP.final_score
        return headersMap
    }
    const showPositionInRanking = () => {
        if (!run) return false
        return Boolean(score)
    }

    const judgeRankingList = showPositionInRanking()
        ? liveManager.getJudgeRankingWithPotentialPosition(
              judgeId,
              score,
              run!.name,
              run!.round_number
          )
        : liveManager.getJudgeRanking(judgeId)

    const getHighlightedMap = () => {
        if (run && score && liveManager.stageFormat === "2z3") {
            const rankingEntryIndex = judgeRankingList.findIndex(
                (el) => el.name === run.name
            )
            if (rankingEntryIndex === -1) return {}

            return { [rankingEntryIndex]: "yellowgreen" }
        } else if (run && score && liveManager.stageFormat === "1z2") {
            const rankingEntryIndex = judgeRankingList.findIndex(
                (el) => el.name === run.name
            )
            if (rankingEntryIndex === -1) return {}

            const rankingEntry = judgeRankingList[rankingEntryIndex]

            const drawIndex = judgeRankingList.findIndex(
                (el) =>
                    el.name !== run.name &&
                    el[`final_score_judge_${judgeIndex}`] ===
                        rankingEntry[`final_score_judge_${judgeIndex}`]
            )

            if (drawIndex > -1) {
                return {
                    [rankingEntryIndex]: "#FF474C",
                    [drawIndex]: "#FF474C",
                }
            } else {
                return { [rankingEntryIndex]: "yellowgreen" }
            }
        } else return {}
    }

    return (
        <>
            <GenericTable
                tableId={`judge-${judgeId}-ranking`}
                rowId={"athlete_id"}
                headersMap={getHeadersMap()}
                // @ts-ignore
                rows={judgeRankingList}
                highlightedMap={getHighlightedMap()}
            />
        </>
    )
}
