import { useState } from "react"

import { Tab, Tabs } from "@mui/material"

import { LiveManager } from "../LiveManager"
import JudgeRankingList from "../components/JudgeRankingList"
import RankingList from "../components/RankingList"
import StartList from "../components/StartList"

export default function IndividualSummaries({
    judgeId,
    liveManager,
    score,
}: {
    judgeId: string
    liveManager: LiveManager
    score: any
}) {
    const [summaryIndex, setSummaryIndex] = useState(1)

    const summaries = ["Start List", "My Ranking", "Ranking"]

    return (
        <>
            <>
                <Tabs
                    value={summaryIndex}
                    onChange={(event, newValue) => setSummaryIndex(newValue)}
                    sx={{ mb: 1 }}
                >
                    {summaries.map((s, i) => (
                        <Tab label={s} key={i} />
                    ))}
                </Tabs>

                {summaryIndex === 0 && <StartList liveManager={liveManager} />}
                {summaryIndex === 1 && (
                    <JudgeRankingList
                        judgeId={judgeId}
                        liveManager={liveManager}
                        score={score}
                    />
                )}
                {summaryIndex === 2 && (
                    <RankingList liveManager={liveManager} />
                )}
            </>
        </>
    )
}
