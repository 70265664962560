import { useContext, useEffect, useState } from "react"

import { BACKEND_URL } from "../../config"
import { UserDataContext } from "../../pages/app/UserDataProvider"
import { prepareError } from "../utils"
import useApi from "./useApi"

interface Stage {
    id: string
    date: string
    name: string
    form: string
    status: undefined | string
    additional_format_settings: any
    athletes_for_next_stage: number
    only_fis_for_next_stage?: boolean
    rounds: [{ id: string }]
}

export interface Competition {
    id: string
    event: string
    category: string
    discipline: string
    name: string
    rank: string
    sport: string
    stages: Array<Stage>
    judges: any
}

interface useCompetitionApiProps {
    competitionId: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useCompetitionApi(props: useCompetitionApiProps) {
    const {
        isLoading: isCompetitionLoading,
        refetch: refetchCompetition,
        error: competitionError,
        data: competition,
    } = useApi<Competition>({
        url: `${BACKEND_URL}/api/events/competitions/${props.competitionId}/`,
        enabled: props.enabled,
        onSuccess: props.onSuccess,
        onError: props.onError,
    })

    return {
        isCompetitionLoading,
        competitionError,
        refetchCompetition,
        competition,
    }
}
