import { useContext } from "react"

import { BACKEND_URL } from "../../config"
import { NotificationContext } from "../../hooks/useNotification"
import useApi from "./useApi"

interface Clinic {
    id: string
    name: string
}

interface useClinicApiProps {
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useClinicsApi(props: useClinicApiProps) {
    const { updateNotification } = useContext(NotificationContext)!
    const {
        isLoading: isClinicsLoading,
        error: clinicsError,
        refetch: refetchClinics,
        data: clinics,
    } = useApi<Clinic[]>({
        url: `${BACKEND_URL}/api/clinics/`,
        enabled: props.enabled,
        onSuccess: (data) => props.onSuccess && props.onSuccess(data),
        onError: (err) => {
            updateNotification({
                open: true,
                severity: "error",
                text: err?.toString(),
            })
            props.onError && props.onError(err)
        },
    })

    return { isClinicsLoading, clinicsError, refetchClinics, clinics }
}
