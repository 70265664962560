import { createContext, useContext, useEffect, useState } from "react"
import useWebSocket, { ReadyState, SendMessage } from "react-use-websocket"

import { BACKEND_WS } from "../../config"
import { UserDataContext } from "./UserDataProvider"

export const enum MessageTypes {
    Any = "any",
    Register = "register",
    Unregister = "unregister",
}
export const enum Namespaces {
    Competition = "competition",
    Exercise = "exercise",
}

export const WebSocketContext = createContext<
    | undefined
    | {
          connectionStatus: undefined | string
          sendMessage: SendMessage
          addWebSocketHandler: (
              id: string,
              func: (payload: any) => void
          ) => void
          removeWebSocketHandler: (id: string) => void
      }
>(undefined)

export default function WebSocketProvider({
    children,
}: {
    children: JSX.Element
}) {
    const { token } = useContext(UserDataContext)!

    const [connectionStatus, setConnectionStatus] = useState<
        undefined | string
    >(undefined)

    const [webSocketHandlers, setWebSocketHandlers] = useState<{
        [key: string]: (event: MessageEvent) => void
    }>({})

    const addWebSocketHandler = (
        id: string,
        func: (event: MessageEvent) => void
    ) => {
        if (!(id in webSocketHandlers)) {
            setWebSocketHandlers({ ...webSocketHandlers, [id]: func })
        }
    }
    const removeWebSocketHandler = (id: string) => {
        if (id in webSocketHandlers) {
            delete webSocketHandlers[id]
        }
    }

    const { sendMessage, readyState } = useWebSocket(
        `${BACKEND_WS}/?token=${token}`,
        {
            shouldReconnect: (closeEvent) => true,
            onMessage: (event) => {
                try {
                    const payload: any = JSON.parse(event.data)
                    switch (payload?.channel_type) {
                        case Namespaces.Exercise:
                        case Namespaces.Competition:
                            Object.entries(webSocketHandlers)
                                .filter(([id]) =>
                                    id.startsWith(
                                        `${payload.channel_type}:${payload?.instance_id}`
                                    )
                                )
                                .forEach(([, handler]) =>
                                    handler(payload?.message)
                                )
                            return
                        default:
                            return
                    }
                } catch (e) {}
            },
        }
    )

    useEffect(() => {
        const localConnectionStatus = {
            [ReadyState.CONNECTING]: "Connecting",
            [ReadyState.OPEN]: "Open",
            [ReadyState.CLOSING]: "Closing",
            [ReadyState.CLOSED]: "Closed",
            [ReadyState.UNINSTANTIATED]: "Uninstantiated",
        }[readyState]
        setConnectionStatus(localConnectionStatus)

        return () => setConnectionStatus(undefined)
    }, [readyState])

    return (
        <>
            <WebSocketContext.Provider
                value={{
                    connectionStatus,
                    sendMessage,
                    addWebSocketHandler,
                    removeWebSocketHandler,
                }}
            >
                {children}
            </WebSocketContext.Provider>
        </>
    )
}
