import { BACKEND_URL } from "../../config"
import useApi from "./useApi"

export interface Stage {
    id: string
    status: string
    competition: string
    athletes_for_next_stage: number
    only_fis_for_next_stage?: boolean
    date: string
    name: string
    form: string
    judges: {
        individuals: string[]
        head: string
    }
}

interface useStageApiProps {
    stageId: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useStageApi(props: useStageApiProps) {
    const {
        isLoading: isStageLoading,
        refetch: refetchStage,
        error: stageError,
        data: stage,
    } = useApi<Stage>({
        url: `${BACKEND_URL}/api/events/stages/${props.stageId}/`,
        enabled: props.enabled,
        onSuccess: props.onSuccess,
        onError: props.onError,
    })

    return { isStageLoading, refetchStage, stageError, stage }
}
