import { useContext } from "react"

import { BACKEND_URL } from "../../config"
import { NotificationContext } from "../../hooks/useNotification"
import useApi from "./useApi"

export interface ClinicExercise {
    id: string
    competition_data: {
        name: string
        rank: string
        sport: string
        category: string
        discipline: string
    }
    stage_data: {
        date: string
        form: string
        name: string
        round_amount: string
    }
}

interface useClinicExercisesApiProps {
    clinicExerciseId: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useClinicExerciseApi(
    props: useClinicExercisesApiProps
) {
    const { updateNotification } = useContext(NotificationContext)!
    const {
        isLoading: isClinicExerciseLoading,
        error: clinicExerciseError,
        refetch: refetchClinicExercise,
        data: clinicExercise,
    } = useApi<ClinicExercise>({
        url: `${BACKEND_URL}/api/clinics/clinic-exercises/${props.clinicExerciseId}/`,
        enabled: props.enabled,
        onSuccess: (data) => props.onSuccess && props.onSuccess(data),
        onError: (err) => {
            updateNotification({
                open: true,
                severity: "error",
                text: err?.toString(),
            })
            props.onError && props.onError(err)
        },
    })

    return {
        isClinicExerciseLoading,
        clinicExerciseError,
        refetchClinicExercise,
        clinicExercise,
    }
}
