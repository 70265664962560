import React from "react"
import ReactDOM from "react-dom/client"

import { Amplify } from "aws-amplify"

import App from "./App"
import "./App.css"
import awsExports from "./aws-exports"
import "./index.css"
import reportWebVitals from "./reportWebVitals"

Amplify.configure({ ...awsExports })

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

reportWebVitals()
