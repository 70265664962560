import { createContext, Dispatch, SetStateAction, useState } from "react"

import useToken from "../../hooks/useToken"
import useUserData, { UserData } from "../../hooks/useUserData"

export const UserDataContext = createContext<
    | undefined
    | {
          token: string
          userData: UserData
          authRedirect: () => void
          judgePosition: string | undefined
          setJudgePosition: Dispatch<SetStateAction<string | undefined>>
      }
>(undefined)

export default function UserDataProvider({
    children,
}: {
    children: JSX.Element
}) {
    const [judgePosition, setJudgePosition] = useState<string | undefined>(
        undefined
    )
    const { getToken, removeToken } = useToken()
    const { getUserData, removeUserData } = useUserData()

    const authRedirect = () => {
        removeToken()
        removeUserData()
    }

    return (
        <>
            <UserDataContext.Provider
                value={{
                    token: getToken()!,
                    userData: getUserData()!,
                    authRedirect,
                    judgePosition,
                    setJudgePosition,
                }}
            >
                {children}
            </UserDataContext.Provider>
        </>
    )
}
