import { BACKEND_URL } from "../../config"
import useApi from "./useApi"

interface Run {
    id: string
    bib: number
    name: string
    nsa_code: string
    order_number: number
    round_number: number
    stance: string
    score:
        | undefined
        | {
              score: string
          }
}

interface useNextRunApiProps {
    stageId: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useNextRunApi(props: useNextRunApiProps) {
    const {
        isLoading: isNextRunLoading,
        refetch: refetchNextRun,
        error: nextRunError,
        data: run,
    } = useApi<Run | undefined>({
        url:
            `${BACKEND_URL}/api/events/runs/next/?` +
            new URLSearchParams({ stage_id: props.stageId }),
        enabled: props.enabled,
        onSuccess: props.onSuccess,
        onError: props.onError,
    })

    return { isNextRunLoading, refetchNextRun, nextRunError, run }
}
