const stringify = (value: unknown) => {
    if (typeof value === "string")
        return JSON.stringify(JSON.parse(value), null, 2)
    else return JSON.stringify(value, null, 2)
}

export const prepareError = (res: Response) => {
    return res.text().then((text) => {
        text = JSON.parse(text)
        if (typeof text === "object" && "detail" in text) {
            if (Array.isArray(text["detail"])) {
                throw stringify(text["detail"][0])
            } else throw stringify(text["detail"])
        }
        throw stringify(text)
    })
}
