import { useNavigate } from "react-router-dom"

import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from "@mui/material"

import useEventsApi from "../../../api/app/useEventsApi"
import LoadingLayout from "../../../layouts/LoadingLayout"

export default function Events() {
    const navigate = useNavigate()

    const { isEventsLoading, eventsError, events } = useEventsApi({
        enabled: true,
    })

    if (isEventsLoading) return <LoadingLayout />

    if (eventsError) return "An error has occurred: " + eventsError

    return (
        <>
            <Grid
                container
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid item xs={12} md={6}>
                    {events &&
                        events.map((eventData) => (
                            <Card key={eventData.competition.id} sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                    >
                                        {eventData.event_name}
                                    </Typography>

                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                    >
                                        {eventData.competition.name}
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Sport: {eventData.competition.sport}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Discipline:{" "}
                                        {eventData.competition.discipline}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Category:{" "}
                                        {eventData.competition.category}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Stages:{" "}
                                        {eventData.competition.stages.length}
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        fontWeight={"bold"}
                                    >
                                        Your role:{" "}
                                        {eventData.is_head
                                            ? "Head Judge"
                                            : "Individual Judge"}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    {eventData.is_head ? (
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                navigate(
                                                    `/app/competitions/${eventData.competition.id}`
                                                )
                                            }
                                        >
                                            See details
                                        </Button>
                                    ) : (
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                navigate(
                                                    `/app/competitions/${eventData.competition.id}/live`
                                                )
                                            }
                                        >
                                            Start judging
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        ))}
                </Grid>
            </Grid>
        </>
    )
}
