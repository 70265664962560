export const API_MAP = {
    average: "Av",
    score: "Score",
    bib: "Bib",
    final_score: "Final Score",
    fis_code: "FIS CODE",
    fis_points: "FIS POINTS",
    name: "Name",
    nsa_code: "NSA Code",
    order_number: "Start Order",
    rank: "Rank",
    stance: "Stance",
    trick_id: "Trick ID",
    yb: "YB",
    enter: "Enter",
    round_number: "Round Number",
    original_score: "Original Score",
}
