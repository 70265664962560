import { useNavigate } from "react-router-dom"

import { fetchAuthSession, signOut } from "@aws-amplify/auth"
import { AuthUser } from "aws-amplify/auth"

import useUserDataApi from "../api/useUserDataApi"
import useToken from "./useToken"
import useUserData from "./useUserData"

export default function useAuth() {
    const navigate = useNavigate()

    const { setUserData } = useUserData()
    const { removeToken, setToken } = useToken()
    const { getUserData } = useUserDataApi({
        onSuccess: (data: any) => {
            setUserData(data)
            if (data.is_master) navigate("/app/clinics")
            else if (data.is_judge) navigate("/app")
        },
        onError: (err) => handleSignOut(),
    })

    const handleSignIn = (user: AuthUser) => {
        fetchAuthSession().then((data) => {
            let token = data.tokens?.idToken?.toString()

            if (token) {
                setToken(token)
                getUserData.mutate({ token: token })
            }
        })
    }

    const handleSignOut = () => {
        signOut().then((r) => {
            removeToken()
            navigate("/")
        })
    }

    return {
        handleSignIn,
        handleSignOut,
        isLoading: getUserData.isLoading,
    }
}
