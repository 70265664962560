import { useNavigate, useParams } from "react-router-dom"

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import { Button } from "@mui/material"

export default function BackButton() {
    const navigate = useNavigate()
    return (
        <Button
            onClick={() => navigate(-1)}
            sx={{ mb: 2 }}
            variant={"outlined"}
        >
            <KeyboardBackspaceIcon />
            Back
        </Button>
    )
}
