import * as React from "react"

import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { styled } from "@mui/material/styles"

interface GenericTableProps {
    tableId: string
    rowId: string
    headersMap: {
        [key: string]: string
    }
    rows: any[]
    highlightedMap?: { [key: number]: string }
    redHorizontal?: number
    bolded?: string[]
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}))

export default function GenericTable({
    tableId,
    rowId,
    headersMap,
    rows,
    highlightedMap,
    redHorizontal,
    bolded = [],
}: GenericTableProps) {
    const getHighlightColor = (index: number): string | undefined => {
        if (!highlightedMap) {
            return undefined
        }
        if (!(index in highlightedMap)) {
            return undefined
        }
        return highlightedMap[index]
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table" size={"small"}>
                <TableHead>
                    <StyledTableRow>
                        {Object.values(headersMap).map(
                            (header: string, index: number) => (
                                <StyledTableCell
                                    sx={{ px: "2px", py: "0px" }}
                                    key={`${tableId}-header-cell-${index}-${header.replaceAll(" ", "")}`}
                                >
                                    {header.split(" ").map((el, index) => (
                                        <span key={index}>
                                            {index !== 0 && <br />}
                                            {el}
                                        </span>
                                    ))}
                                </StyledTableCell>
                            )
                        )}
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <StyledTableRow
                            key={`${tableId}-${row[rowId]}-body-row-${index}`}
                            sx={
                                getHighlightColor(index)
                                    ? {
                                          backgroundColor: `${getHighlightColor(index)} !important`,
                                      }
                                    : {}
                            }
                            style={
                                redHorizontal && redHorizontal === index
                                    ? {
                                          borderBottom: "2px solid red",
                                      }
                                    : {}
                            }
                        >
                            {Object.keys(headersMap).map(
                                (key: string, index) => (
                                    <StyledTableCell
                                        sx={{
                                            px: "2px",
                                            py: "0px",
                                            minWidth: "10px",
                                            fontWeight:
                                                bolded.indexOf(
                                                    headersMap[key]
                                                ) > -1
                                                    ? "600"
                                                    : "300",
                                        }}
                                        key={`${tableId}-${row[rowId]}-body-cell-${index}-${key}`}
                                    >
                                        {row[key]}
                                    </StyledTableCell>
                                )
                            )}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
