import { useEffect } from "react"

const usePhoneLockUnlock = (onUnlocking: () => void) => {
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                if (onUnlocking) {
                    onUnlocking()
                }
            }
        }

        const handleFocus = () => {
            if (onUnlocking) {
                onUnlocking()
            }
        }

        document.addEventListener("visibilitychange", handleVisibilityChange)
        window.addEventListener("focus", handleFocus)

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange
            )
            window.removeEventListener("focus", handleFocus)
        }
    }, [onUnlocking])
}

export default usePhoneLockUnlock
