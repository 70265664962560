import {
    FormEvent,
    forwardRef,
    ReactElement,
    Ref,
    useRef,
    useState,
} from "react"
import { useMutation, useQuery } from "react-query"

import CloseIcon from "@mui/icons-material/Close"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Slide,
} from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"
import Cookies from "universal-cookie"

import useToken from "./useToken"

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function useDialogForm(props: {
    name: string
    endpoint: string
    onSuccess: (data: any) => void
    onFailure: (error: unknown) => void
}) {
    const CSRF_TOKEN_COOKIE = "csrftoken"
    const cookies = new Cookies()
    const { getToken } = useToken()

    const [open, setOpen] = useState<boolean>(false)
    const handleClose = () => {
        setOpen(false)
    }

    const formRef = useRef(null)

    const { data: rawForm } = useQuery<string>(
        props.name.replaceAll(" ", "-"),
        () => {
            // cookies.remove(CSRF_TOKEN_COOKIE)
            return fetch(props.endpoint, {
                headers: { Authorization: getToken()! },
            })
                .then((res) => res.blob())
                .then((data) => data.text())
        },
        {
            refetchOnWindowFocus: false,
        }
    )

    const postForm = useMutation({
        mutationFn: (formData: URLSearchParams) =>
            fetch(props.endpoint, {
                method: "POST",
                headers: {
                    Authorization: getToken()!,
                    X_CSRFTOKEN: cookies.get(CSRF_TOKEN_COOKIE)!,
                },
                body: formData,
                redirect: "manual",
            }).then((res) => {
                if (!res.ok) {
                    throw res.statusText
                }
            }),
        onSuccess: (data) => {
            props.onSuccess(data)
        },
        onError: (error) => {
            props.onFailure(error)
        },
    })

    const onSubmit = (event: FormEvent) => {
        event.preventDefault()
        setOpen(false)
        // @ts-ignore
        const formData = new URLSearchParams(new FormData(event.currentTarget))
        postForm.mutate(formData)
    }

    const DialogForm = () => (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
                    {props.name}
                    <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                {rawForm && (
                    <>
                        <form ref={formRef} onSubmit={onSubmit}>
                            <DialogContent>
                                <DialogContentText>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: rawForm,
                                        }}
                                    />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ px: "24px" }}>
                                <Button type={"submit"} sx={{ px: "0px" }}>
                                    Submit
                                </Button>
                            </DialogActions>
                        </form>
                    </>
                )}
            </Dialog>
        </>
    )

    return { DialogForm, setOpen }
}
