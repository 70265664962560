import React, { useContext, useEffect } from "react"

import { Typography } from "@mui/material"

import { Competition } from "../../../api/app/useCompetitionApi"
import { Namespaces, WebSocketContext } from "../../app/WebSocketProvider"

export default function IndividualJudgeLiveWaiting(props: {
    competition: Competition
    setSignal: React.Dispatch<React.SetStateAction<string | undefined>>
}) {
    const { addWebSocketHandler, removeWebSocketHandler } =
        useContext(WebSocketContext)!

    useEffect(() => {
        if (props.competition) {
            const handlerId = `${Namespaces.Competition}:${props.competition.id}:reload`
            addWebSocketHandler(handlerId, (payload: any) => {
                if (payload && payload?.type === "reload") {
                    props.setSignal("reload")
                }
            })
            return () => removeWebSocketHandler(handlerId)
        }
    }, [addWebSocketHandler, removeWebSocketHandler, props.competition])

    return (
        <>
            <Typography align={"center"} variant={"h6"}>
                Waiting for start by head judge
            </Typography>
        </>
    )
}
