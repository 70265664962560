import { useContext, useEffect, useState } from "react"

import { Card, CardContent, Grid, Tab, Tabs, Typography } from "@mui/material"

import {
    MessageTypes,
    Namespaces,
    WebSocketContext,
} from "../../app/WebSocketProvider"
import BackButton from "../../app/master/components/BackButton"
import { LiveContext } from "../LiveStageWrapper"
import HeadSummaries from "./HeadSummaries"
import ConfirmScore from "./components/ConfirmScore"
import ConfirmScoreWithTrick from "./components/ConfirmScoreWithTrick"

export default function HeadJudgeLiveCompetition() {
    const { competition, stage, run, liveManager } = useContext(LiveContext)!

    const { sendMessage } = useContext(WebSocketContext)!

    const [scores, setScores] = useState<{ [key: string]: any }>({})
    const [trickId, setTrickId] = useState<string>("")

    useEffect(() => {
        sendMessage(
            JSON.stringify({
                type: MessageTypes.Any,
                channel_type: Namespaces.Competition,
                instance_id: competition.id,
                message: {
                    type: "reload",
                },
            })
        )
    }, [])

    return (
        <Grid container>
            <Grid item xs={12}>
                <BackButton />
            </Grid>
            <Grid item xs={7}>
                <HeadSummaries
                    liveManager={liveManager}
                    scores={scores}
                    trickId={trickId}
                />
            </Grid>
            <Grid item xs={5} sx={{ pl: "20px" }}>
                <Tabs value={0} sx={{ mb: 1 }}>
                    <Tab label={"Athlete"} />
                </Tabs>
                {run ? (
                    <>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h5">{run.name}</Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    Category: {competition.category}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    Stage: {stage.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    Run: {run.round_number}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    Start Number: {run.order_number}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    Bib: {run.bib}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    Stance: {run.stance}
                                </Typography>
                            </CardContent>
                        </Card>
                        {stage.form === "2z3" ? (
                            <ConfirmScoreWithTrick
                                setScores={setScores}
                                setTrickId={setTrickId}
                            />
                        ) : (
                            <ConfirmScore setScores={setScores} />
                        )}
                    </>
                ) : (
                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography>No next athlete</Typography>
                        </CardContent>
                    </Card>
                )}
            </Grid>
        </Grid>
    )
}
