import React from "react"
import { useNavigate } from "react-router-dom"

import {
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
} from "@mui/material"

import usePublicEventsApi from "../../api/public/usePublicEventsApi"
import LoadingLayout from "../../layouts/LoadingLayout"
import PrimaryLayout from "../../layouts/PrimaryLayout"

export default function PublicEvents() {
    const navigate = useNavigate()

    const { isEventsLoading, eventsError, events } = usePublicEventsApi({
        enabled: true,
    })

    if (isEventsLoading) return <LoadingLayout />

    if (eventsError) return "An error has occurred: "

    return (
        <PrimaryLayout>
            <>
                {events &&
                    events.map((event) => (
                        <Card key={event.id} sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                >
                                    {event.name}
                                </Typography>
                                {event.competitions.map((competition) => (
                                    <Card key={event.id} sx={{ mb: 2 }}>
                                        <CardContent>
                                            <Typography variant="body1">
                                                {competition.name}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                Sport: {competition.sport}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                Discipline:{" "}
                                                {competition.discipline}
                                            </Typography>{" "}
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                Category: {competition.category}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                size="small"
                                                onClick={() =>
                                                    navigate(
                                                        `/public/competitions/${competition.id}`
                                                    )
                                                }
                                            >
                                                Ranking
                                            </Button>
                                        </CardActions>
                                    </Card>
                                ))}
                            </CardContent>
                        </Card>
                    ))}
            </>
        </PrimaryLayout>
    )
}
