import { useContext, useEffect, useState } from "react"

import { BACKEND_URL } from "../../config"
import { UserDataContext } from "../../pages/app/UserDataProvider"
import { prepareError } from "../utils"

interface Candidate {
    id: string
    order_number: string
    fis_code: string
    bib: string
    name: string
    nsa_code: string
    yb: string
    stance: string
    fis_points: string
    enter: boolean
}

interface useCandidatesApiProps {
    competitionId: string
    enabled: boolean
    onSuccess?: (data: Candidate[]) => void
    onError?: (err: unknown) => void
}

export default function useCandidatesApi(props: useCandidatesApiProps) {
    const { token, authRedirect } = useContext(UserDataContext)!

    const [isCandidatesLoading, setIsCandidatesLoading] = useState(false)
    const [candidatesError, setCandidatesError] = useState<unknown>(null)
    const [candidates, setCandidates] = useState<Candidate[] | null>(null)

    const fetchCandidates = async () => {
        setIsCandidatesLoading(true)
        setCandidatesError(null)

        try {
            const response = await fetch(
                `${BACKEND_URL}/api/events/candidates/?` +
                    new URLSearchParams({
                        competition_id: props.competitionId,
                    }),
                {
                    headers: { Authorization: token },
                }
            )

            if (response.status === 401 || response.status === 403) {
                authRedirect()
            } else if (!response.ok) {
                const error = await prepareError(response)
                setCandidatesError(error)
                if (props.onError) props.onError(error)
            } else {
                const data: Candidate[] = await response.json()
                const candidatesData = data.map((candidate: Candidate) =>
                    Object.assign(candidate, {
                        enter: candidate.enter,
                    })
                )
                setCandidates(candidatesData)
                if (props.onSuccess) props.onSuccess(candidatesData)
            }
        } catch (error) {
            setCandidatesError(error)
            if (props.onError) props.onError(error)
        } finally {
            setIsCandidatesLoading(false)
        }
    }

    useEffect(() => {
        if (props.enabled) {
            fetchCandidates()
        }
    }, [props.enabled, props.competitionId])

    return {
        isCandidatesLoading,
        candidatesError,
        refetchCandidates: fetchCandidates,
        candidates,
    }
}
