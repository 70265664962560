import { Fragment } from "react"
import { useNavigate, useParams } from "react-router-dom"

import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from "@mui/material"

import useCompetitionApi from "../../../api/app/useCompetitionApi"
import useManageCompetitionApi from "../../../api/app/useManageCompetitionApi"
import useConfirmDialog from "../../../hooks/useConfirmDialog"
import useDialog from "../../../hooks/useDialog"
import LoadingLayout from "../../../layouts/LoadingLayout"
import JudgeEditDialog from "../components/JudgeEditDialog"
import BackButton from "../master/components/BackButton"

export default function CompetitionPage() {
    const navigate = useNavigate()
    const { competitionId } = useParams()

    const { ConfirmDialog, confirm } = useConfirmDialog()

    const {
        isCompetitionLoading,
        competitionError,
        refetchCompetition,
        competition,
    } = useCompetitionApi({
        competitionId: competitionId!,
        enabled: Boolean(competitionId),
    })

    const { manageCompetition: startStage } = useManageCompetitionApi({
        competitionId: competitionId!,
        manage: "start",
        onSuccess: (data) => refetchCompetition(),
    })

    const { manageCompetition: endStage } = useManageCompetitionApi({
        competitionId: competitionId!,
        manage: "end",
        onSuccess: (data) => refetchCompetition(),
    })

    const getStatus = (status: string | undefined) => {
        if (status === "E") return "Ended"
        else if (status === "S") return "In progress"
        else return ""
    }

    const isPreviousStageEnded = (index: number) => {
        if (index === 0) return true
        else return competition?.stages[index - 1].status === "E"
    }
    const isFirstStage = (index: number) => {
        return index === 0
    }

    const { DialogForm, setOpen, open } = useDialog({ title: "Edit Judges" })

    if (isCompetitionLoading) return <LoadingLayout />

    if (competitionError) return "An error has occurred: " + competitionError

    return (
        <>
            <Grid
                container
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid item xs={12} md={6}>
                    <BackButton />
                    {competition && (
                        <>
                            {/*@ts-ignore*/}
                            <JudgeEditDialog
                                // @ts-ignore
                                DialogForm={DialogForm}
                                competitionId={competition.id}
                                open={open}
                            />
                            <ConfirmDialog />
                            <Card>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                    >
                                        {competition.name}
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Sport: {competition.sport}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Discipline: {competition.discipline}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Category: {competition.category}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Rank: {competition.rank}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Stages: {competition.stages.length}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    {competition.stages &&
                                        getStatus(
                                            competition.stages[0].status
                                        ) === "" && (
                                            <Button
                                                onClick={() => setOpen(true)}
                                            >
                                                Judges
                                            </Button>
                                        )}
                                </CardActions>
                            </Card>
                            <Typography variant={"h6"} mt={3}>
                                Stages
                            </Typography>
                            {competition.stages.map((stage, index) => (
                                <Card key={stage.id} sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                        >
                                            {stage.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            Data: {stage.date}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            Format: {stage.form}
                                        </Typography>
                                        {stage.athletes_for_next_stage && (
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                Athletes passing to the next
                                                stage:{" "}
                                                {stage.athletes_for_next_stage}
                                            </Typography>
                                        )}
                                        {stage.only_fis_for_next_stage && (
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                Only FIS for next stage:{" "}
                                                {JSON.stringify(
                                                    stage.only_fis_for_next_stage
                                                )}
                                            </Typography>
                                        )}
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            fontWeight={"bold"}
                                        >
                                            Status: {getStatus(stage.status)}
                                        </Typography>
                                        <CardActions>
                                            {getStatus(stage.status) === "" && (
                                                <>
                                                    {isFirstStage(index) && (
                                                        <Button
                                                            onClick={() =>
                                                                navigate(
                                                                    `/app/competitions/${competitionId}/initial-candidates/`
                                                                )
                                                            }
                                                        >
                                                            Qualified List
                                                        </Button>
                                                    )}
                                                    {isPreviousStageEnded(
                                                        index
                                                    ) && (
                                                        <>
                                                            {!isFirstStage(
                                                                index
                                                            ) && (
                                                                <Button
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/app/competitions/${competitionId}/candidates/`
                                                                        )
                                                                    }
                                                                >
                                                                    Qualified
                                                                    List
                                                                </Button>
                                                            )}
                                                            <Button
                                                                onClick={() =>
                                                                    confirm(
                                                                        "Are you sure?",
                                                                        <Fragment>
                                                                            <Typography>
                                                                                You
                                                                                will
                                                                                not
                                                                                be
                                                                                able
                                                                                edit
                                                                                data
                                                                                on
                                                                                the
                                                                                Start
                                                                                List.
                                                                            </Typography>
                                                                        </Fragment>,
                                                                        () =>
                                                                            startStage.mutate()
                                                                    )
                                                                }
                                                            >
                                                                Start
                                                            </Button>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {getStatus(stage.status) ===
                                                "In progress" && (
                                                <>
                                                    <Button
                                                        onClick={() =>
                                                            navigate(
                                                                `/app/competitions/${competitionId}/live/`
                                                            )
                                                        }
                                                        color={"warning"}
                                                    >
                                                        Live
                                                    </Button>

                                                    <Button
                                                        onClick={() =>
                                                            confirm(
                                                                "Are you sure?",
                                                                <Fragment>
                                                                    <Typography>
                                                                        You will
                                                                        not be
                                                                        able
                                                                        edit
                                                                        runs.
                                                                    </Typography>
                                                                </Fragment>,
                                                                () =>
                                                                    endStage.mutate()
                                                            )
                                                        }
                                                    >
                                                        End Stage
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            navigate(
                                                                `/app/stages/${stage.id}/ranking/`
                                                            )
                                                        }
                                                    >
                                                        Ranking
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            navigate(
                                                                `/app/stages/${stage.id}/runs/`
                                                            )
                                                        }
                                                    >
                                                        Edit runs
                                                    </Button>
                                                </>
                                            )}
                                            {getStatus(stage.status) ===
                                                "Ended" && (
                                                <>
                                                    <Button
                                                        onClick={() =>
                                                            navigate(
                                                                `/app/stages/${stage.id}/ranking/`
                                                            )
                                                        }
                                                    >
                                                        Ranking
                                                    </Button>
                                                </>
                                            )}
                                        </CardActions>
                                    </CardContent>
                                </Card>
                            ))}
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    )
}
