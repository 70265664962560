import React, { Fragment, useContext } from "react"
import { useParams } from "react-router-dom"

import { Button, Grid, Typography } from "@mui/material"

import useAddCustomScoreApi from "../../../api/app/useAddCustomScoreApi"
import useRankingApi from "../../../api/app/useRankingApi"
import useResetScoreApi from "../../../api/app/useResetScoreApi"
import useRunsApi from "../../../api/app/useRunsApi"
import useStageApi from "../../../api/app/useStageApi"
import { BACKEND_URL } from "../../../config"
import useConfirmDialog from "../../../hooks/useConfirmDialog"
import useDynamicDialogForm from "../../../hooks/useDynamicDialogForm"
import { NotificationContext } from "../../../hooks/useNotification"
import { LiveManager } from "../../live/LiveManager"
import GenericTable from "../../live/components/GenericTable"
import { API_MAP } from "../constants"
import BackButton from "../master/components/BackButton"

export default function RunsPage() {
    const { stageId } = useParams()

    const { updateNotification } = useContext(NotificationContext)!
    const { ConfirmDialog, confirm } = useConfirmDialog()

    const { stage } = useStageApi({
        stageId: stageId!,
        enabled: Boolean(stageId),
    })

    const { runs, refetchRuns } = useRunsApi({
        stageId: stageId!,
        enabled: Boolean(stage),
    })

    const { refetchRanking, ranking } = useRankingApi({
        stageId: stageId!,
        enabled: Boolean(stage),
    })

    const liveManager =
        stage && ranking
            ? new LiveManager(ranking, stage?.judges.individuals, stage?.form)
            : undefined

    const getHeadersMap = (liveManager: LiveManager) => {
        let headersMap = {
            rank: API_MAP.rank,
            bib: API_MAP.bib,
            name: API_MAP.name,
            stance: API_MAP.stance,
            fis_code: API_MAP.fis_code,
        }

        for (
            let roundNumber = 1;
            roundNumber < liveManager.getRoundAmount() + 1;
            roundNumber++
        ) {
            let roundMap = {}
            for (
                let judgeNumber = 1;
                judgeNumber < liveManager.getJudgeNumber() + 1;
                judgeNumber++
            ) {
                // @ts-ignore
                roundMap[
                    `round_${roundNumber}_judge_${judgeNumber}_partial_score`
                ] = `R${roundNumber} J${judgeNumber}`
            }
            // @ts-ignore
            roundMap[`round_${roundNumber}_score`] =
                `R${roundNumber} ${API_MAP.average}`
            if (liveManager.stageFormat === "2z3") {
                // @ts-ignore
                roundMap[`round_${roundNumber}_trick_id`] =
                    `R${roundNumber} ${API_MAP.trick_id}`
            }

            Object.assign(headersMap, roundMap)
        }

        // @ts-ignore
        headersMap["final_score"] = API_MAP.final_score

        return headersMap
    }
    const { DynamicDialogForm, onForm } = useDynamicDialogForm({
        onFailure: (error) => {
            updateNotification({
                open: true,
                severity: "error",
                text: error?.toString(),
            })
        },
        onSuccess: (data) => {
            updateNotification({
                open: true,
                severity: "success",
                text: "Athlete's run has been edited.",
            })
            refetchRuns()
            refetchRanking()
        },
    })

    const { resetScore } = useResetScoreApi({
        onError: (error) => {
            updateNotification({
                open: true,
                severity: "error",
                text: error?.toString(),
            })
        },
        onSuccess: (data) => {
            updateNotification({
                open: true,
                severity: "success",
                text: "Score erased",
            })
            refetchRuns()
            refetchRanking()
        },
    })

    const { addCustomScore } = useAddCustomScoreApi({
        onError: (error) => {
            updateNotification({
                open: true,
                severity: "error",
                text: error?.toString(),
            })
        },
        onSuccess: (data) => {
            updateNotification({
                open: true,
                severity: "success",
                text: "Athlete's run has been edited.",
            })
            refetchRuns()
            refetchRanking()
        },
    })

    const submitCustomScore = (
        run: any,
        customScore: "DNF" | "DNS" | "DSQ" | string
    ) => {
        if (run) {
            addCustomScore.mutate({
                runId: run.id,
                customScore: customScore,
            })
        }
    }

    const getRows = (r: any) => {
        r.map((run: any) =>
            Object.assign(run, {
                action: (
                    <Fragment>
                        {run.avg === "DSQ" ? (
                            <Button
                                onClick={() =>
                                    confirm(
                                        "Are you sure?",
                                        <Fragment>
                                            <Typography>
                                                You will erase athlete's score.
                                            </Typography>
                                        </Fragment>,
                                        () =>
                                            resetScore.mutate({ runId: run.id })
                                    )
                                }
                            >
                                RESET
                            </Button>
                        ) : (
                            <>
                                <Button
                                    onClick={() => {
                                        onForm(
                                            `${BACKEND_URL}/views/events/runs/${run.id}/edit/`,
                                            "Edit run",
                                            `edit-run-${run.id}`
                                        )
                                    }}
                                    size={"small"}
                                >
                                    Edit
                                </Button>

                                <Button
                                    onClick={() =>
                                        confirm(
                                            "Are you sure?",
                                            <Fragment>
                                                <Typography>
                                                    You will assign DNF to
                                                    athlete.
                                                </Typography>
                                            </Fragment>,
                                            () => submitCustomScore(run, "DNF")
                                        )
                                    }
                                >
                                    DNF
                                </Button>
                                <Button
                                    onClick={() =>
                                        confirm(
                                            "Are you sure?",
                                            <Fragment>
                                                <Typography>
                                                    You will assign DNS to
                                                    athlete.
                                                </Typography>
                                            </Fragment>,
                                            () => submitCustomScore(run, "DNS")
                                        )
                                    }
                                >
                                    DNS
                                </Button>
                                <Button
                                    onClick={() =>
                                        confirm(
                                            "Are you sure?",
                                            <Fragment>
                                                <Typography>
                                                    You will assign DSQ to
                                                    athlete.
                                                </Typography>
                                            </Fragment>,
                                            () => submitCustomScore(run, "DSQ")
                                        )
                                    }
                                >
                                    DSQ
                                </Button>
                                <Button
                                    onClick={() =>
                                        confirm(
                                            "Are you sure?",
                                            <Fragment>
                                                <Typography>
                                                    You will erase athlete's
                                                    score.
                                                </Typography>
                                            </Fragment>,
                                            () =>
                                                resetScore.mutate({
                                                    runId: run.id,
                                                })
                                        )
                                    }
                                >
                                    RESET
                                </Button>
                            </>
                        )}
                    </Fragment>
                ),
            })
        )

        return r
    }

    const getHeaderMap = (liveManager: LiveManager) => {
        let headers = {
            round_number: API_MAP.round_number,
            order_number: API_MAP.order_number,
            bib: API_MAP.bib,
            name: API_MAP.name,
            score: API_MAP.average,
        }

        for (
            let judgeNumber = 1;
            judgeNumber < liveManager.getJudgeNumber() + 1;
            judgeNumber++
        ) {
            // @ts-ignore
            headers[`judge_${judgeNumber}_partial_score`] = `J${judgeNumber}`
        }
        if (stage?.form === "2z3") {
            // @ts-ignore
            headers["trick_id"] = API_MAP.trick_id
        }

        // @ts-ignore
        headers["action"] = "Action"
        return headers
    }
    return (
        <>
            {stage && liveManager && runs && (
                <>
                    <ConfirmDialog />
                    <DynamicDialogForm />
                    <Grid container>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <BackButton />
                            <Typography variant={"h5"} align={"center"}>
                                Runs
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 5 }}>
                            <GenericTable
                                tableId={`runs-${stageId}-table`}
                                rowId={"id"}
                                headersMap={getHeaderMap(liveManager)}
                                // @ts-ignore
                                rows={getRows(runs)}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <Typography variant={"h5"} align={"center"}>
                                Ranking
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <GenericTable
                                tableId={`ranking-${stageId}-page`}
                                rowId={"athlete_id"}
                                headersMap={getHeadersMap(liveManager)}
                                rows={liveManager.getSortedRanking()}
                                redHorizontal={
                                    stage.athletes_for_next_stage - 1
                                }
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    )
}
