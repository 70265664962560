import { useContext } from "react"
import { useMutation } from "react-query"

import { BACKEND_URL } from "../../config"
import { NotificationContext } from "../../hooks/useNotification"
import { UserDataContext } from "../../pages/app/UserDataProvider"
import { prepareError } from "../utils"

interface useManageCompetitionApiProps {
    competitionId: string
    manage: "start" | "end"
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useManageCompetitionApi(
    props: useManageCompetitionApiProps
) {
    const { token, authRedirect } = useContext(UserDataContext)!
    const { updateNotification } = useContext(NotificationContext)!

    const manageCompetition = useMutation({
        mutationFn: () =>
            fetch(
                `${BACKEND_URL}/api/events/competitions/${props.competitionId}/${props.manage}/`,
                {
                    method: "POST",
                    headers: { Authorization: token },
                }
            ).then((res) => {
                if (res.status === 401 || res.status === 403) {
                    authRedirect()
                } else if (!res.ok) {
                    return prepareError(res)
                }
            }),
        onSuccess: (data) => props.onSuccess && props.onSuccess(data),
        onError: (err) => {
            updateNotification({
                open: true,
                severity: "error",
                text: err?.toString(),
            })
            props.onError && props.onError(err)
        },
    })

    return {
        manageCompetition,
    }
}
