import Cookies from "universal-cookie"

export interface UserData {
    id: string
    first_name: string
    last_name: string
    is_athlete: boolean
    is_judge: boolean
    is_master: boolean
}
export default function useUserData() {
    const COOKIE_NAME = "UserData"
    const cookies = new Cookies()
    const getUserData = (): UserData | undefined => {
        return cookies.get(COOKIE_NAME)
    }

    const setUserData = (userData: UserData): void => {
        cookies.set(COOKIE_NAME, JSON.stringify(userData), {
            path: "/",
            maxAge: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).getTime(),
        })
    }

    const removeUserData = (): void => {
        cookies.remove(COOKIE_NAME)
    }

    return { removeUserData, getUserData, setUserData }
}
