import * as XLSX from "xlsx"

export default function useExcel() {
    const convertToSheet = (data: any) => {
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
        return wb
    }
    const downloadExcel = (
        name: string,
        data: any,
        headersMap: { [key: string]: any }
    ) => {
        let newData = []
        for (const d of data) {
            let newDataLine = {}
            for (const [oldField, newField] of Object.entries(headersMap)) {
                // @ts-ignore
                newDataLine[newField] = d[oldField]
            }
            newData.push(newDataLine)
        }
        const wb = convertToSheet(newData)

        XLSX.writeFile(wb, `${name}.xlsx`)
    }
    return { downloadExcel }
}
