import { useQuery } from "react-query"

import { BACKEND_URL } from "../../config"
import { prepareError } from "../utils"

interface Stage {
    id: string
    status: string
    competition: string
    date: string
    name: string
    form: string
    round_amount: number
    athletes_for_next_stage: number
    only_fis_for_next_stage?: boolean
    additional_format_settings: {}
    judges: {
        head: string
        individuals: string[]
    }
}

interface usePublicCurrentStageApiProps {
    competitionId: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function usePublicCurrentStageApi(
    props: usePublicCurrentStageApiProps
) {
    const {
        isLoading: isStageLoading,
        refetch: refetchStage,
        error: stageError,
        data: stage,
    } = useQuery<Stage | undefined>(
        "stage",
        () =>
            fetch(
                `${BACKEND_URL}/public/api/events/competitions/${props.competitionId}/current_stage/`
            ).then((res) => {
                if (res.status === 204) {
                    return undefined
                } else if (!res.ok) {
                    return prepareError(res)
                } else return res.json()
            }),
        {
            refetchOnWindowFocus: false,
            enabled: props.enabled,
            onSuccess: (data) => props.onSuccess && props.onSuccess(data),
            onError: (err) => props.onError && props.onError(err),
            retry: 0,
        }
    )

    return { isStageLoading, refetchStage, stageError, stage }
}
