import React, {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react"
import { Controller, useForm } from "react-hook-form"
import { useHotkeys } from "react-hotkeys-hook"

import { Button, Card, CardContent, Typography } from "@mui/material"

import { NotificationContext } from "../../../../hooks/useNotification"
import {
    MessageTypes,
    Namespaces,
    WebSocketContext,
} from "../../../app/WebSocketProvider"
import { LiveContext } from "../../LiveStageWrapper"
import ScoreField from "../../components/ScoreField"

export default function ProposeScore({
    setScore,
}: {
    setScore: Dispatch<SetStateAction<any>>
}) {
    const { updateNotification } = useContext(NotificationContext)!
    const { userData, run, competition } = useContext(LiveContext)!

    const { sendMessage } = useContext(WebSocketContext)!

    const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] =
        useState<boolean>(true)

    type Inputs = {
        score: number
    }

    const { handleSubmit, control, reset, watch } = useForm<Inputs>()

    useHotkeys(
        "enter",
        (e) => {
            onEnter()
        },
        []
    )

    const onEnter = () => {
        handleSubmit(onSubmit)()
    }

    const onSubmit = (data: Inputs) => {
        setIsSubmitButtonEnabled(false)
        setTimeout(() => setIsSubmitButtonEnabled(true), 2000)
        sendMessage(
            JSON.stringify({
                type: MessageTypes.Any,
                channel_type: Namespaces.Competition,
                instance_id: competition.id,
                message: {
                    type: "add_note",
                    judge_id: userData.id,
                    value: data.score,
                    run_id: run?.id,
                },
            })
        )
        updateNotification({
            open: true,
            severity: "success",
            text: "Score has been sent.",
        })
    }

    useEffect(() => {
        let timer: null | ReturnType<typeof setTimeout> = null
        const subscription = watch((data) => {
            if (timer) clearTimeout(timer)

            timer = setTimeout(() => {
                setScore(Number(data.score))
            }, 700)
        })
        return () => subscription.unsubscribe()
    }, [setScore, watch])

    useEffect(() => {
        if (run?.id)
            reset({
                // @ts-ignore
                score: "",
            })
    }, [run?.id])

    return (
        <>
            {run && (
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <Typography
                            variant={"h5"}
                            sx={{ mb: 2, display: { xs: "none", md: "block" } }}
                        >
                            Score
                        </Typography>

                        <Controller
                            name="score"
                            rules={{
                                required: "This field is mandatory.",
                                min: {
                                    value: 0,
                                    message:
                                        "Value should be greater than or equal to 0",
                                },
                                max: {
                                    value: 100,
                                    message:
                                        "Value should be less than or equal to 100",
                                },
                            }}
                            control={control}
                            render={({
                                field: { ...field },
                                fieldState: { invalid, error },
                            }) => (
                                <ScoreField
                                    field={field}
                                    error={error}
                                    invalid={invalid}
                                    label={"Score"}
                                    onEnter={onEnter}
                                />
                            )}
                        />
                        <Button
                            onClick={onEnter}
                            disabled={!isSubmitButtonEnabled}
                        >
                            Submit
                        </Button>
                    </CardContent>
                </Card>
            )}
        </>
    )
}
