interface RankingEntry {
    athlete_id: string
    bib: string
    name: string
    nsa_code: string
    stance: string
    order_number: number
    rounds: {
        partialScores: number[]
        score: number
    }
    final_score: number

    [key: string]: any
}

// athlete_id,
// bib,
// name,
// nsa_code,
// stance,
// order_number,
// final_score,
// final_score_fb10b11a-2a57-4184-aa42-3cd95e676af2
// final_score_9a2dfcb6-f905-433b-ad10-416adb982786
// final_score_3e9a1de0-79c2-4570-8a12-e886f778d06f
// round_1_fb10b11a-2a57-4184-aa42-3cd95e676af2_partial_score
// round_1_9a2dfcb6-f905-433b-ad10-416adb982786_partial_score
// round_1_3e9a1de0-79c2-4570-8a12-e886f778d06f_partial_score
// round_1_score
// round_1_trick_id
// round_2_fb10b11a-2a57-4184-aa42-3cd95e676af2_partial_score
// round_2_9a2dfcb6-f905-433b-ad10-416adb982786_partial_score
// round_2_3e9a1de0-79c2-4570-8a12-e886f778d06f_partial_score
// round_2_score,
// round_2_trick_id

export class LiveManager {
    ranking: RankingEntry[]
    judges: string[]
    stageFormat: string

    constructor(
        ranking: RankingEntry[],
        judges: string[],
        stageFormat: string
    ) {
        this.ranking = ranking
        this.judges = judges
        this.stageFormat = stageFormat
    }

    getStartList(): RankingEntry[] {
        return this.ranking.sort(
            (a: RankingEntry, b: RankingEntry) =>
                a.order_number - b.order_number
        )
    }

    getRanking(): RankingEntry[] {
        return this.sortByField("final_score", this.ranking, "desc")
    }

    getRankingWithPotentialPosition(
        scores: { [key: string]: number },
        trickId: string,
        athleteName: string,
        round: number
    ): RankingEntry[] {
        let newRanking = this.ranking.map((r) => r)

        const athleteEntry = newRanking.find(
            (rankingEntry) => rankingEntry.name === athleteName
        )
        if (!athleteEntry) {
            return this.getRanking()
        }

        athleteEntry[`round_${round}_score`] = parseFloat(
            (
                Object.values(scores).reduce((a, b) => a + b, 0) /
                Object.values(scores).length
            ).toFixed(2)
        )

        athleteEntry[`round_${round}_trick_id`] = trickId

        this.judges.forEach((judgeId, index) => {
            athleteEntry[`round_${round}_judge_${index + 1}_partial_score`] =
                scores[judgeId]
        })

        if (this.stageFormat === "1z2") {
            let finalScores: number[] = []
            for (
                let roundNumber = 1;
                roundNumber < this.getRoundAmount() + 1;
                roundNumber++
            ) {
                if (athleteEntry[`round_${roundNumber}_score`])
                    finalScores.push(athleteEntry[`round_${roundNumber}_score`])
            }
            athleteEntry["final_score"] =
                Math.round((Math.max(...finalScores) + Number.EPSILON) * 100) /
                100
        } else {
            athleteEntry["final_score"] =
                Math.round(
                    (this.calculateFinalScore(athleteEntry, round) +
                        Number.EPSILON) *
                        100
                ) / 100
        }

        newRanking = this.sortByField("final_score", newRanking, "desc")
        newRanking.map((rankingEntry: RankingEntry, i: number) =>
            Object.assign(rankingEntry, { rank: i + 1 })
        )

        return newRanking
    }

    calculateFinalScore(athleteEntry: RankingEntry, round: number): number {
        let aggregatedScores = new Map()
        for (let i = 0; i < round; i++) {
            let score = athleteEntry[`round_${i + 1}_score`]
            let trickId = athleteEntry[`round_${i + 1}_trick_id`]
            if (!trickId) continue

            let currentScore = aggregatedScores.get(trickId)
            if (!currentScore || score >= currentScore) {
                aggregatedScores.set(trickId, score)
            }
        }

        let scores = Array.from(aggregatedScores.values()).sort().reverse()
        if (scores.length === 1) {
            return scores[0]
        } else {
            return scores[0] + scores[1]
        }
    }

    getSortedRanking(): RankingEntry[] {
        let newRanking = this.sortByField("final_score", this.ranking, "desc")
        newRanking.map((rankingEntry: RankingEntry, i: number) =>
            Object.assign(rankingEntry, { rank: i + 1 })
        )
        return newRanking
    }

    getSortedFISRanking(): RankingEntry[] {
        let newRanking = this.sortByField(
            "final_score",
            this.ranking,
            "desc"
        ).filter((r) => r.fis_code)

        newRanking.map((rankingEntry: RankingEntry, i: number) =>
            Object.assign(rankingEntry, { rank: i + 1 })
        )
        return newRanking
    }

    getJudgeRanking(judgeId: string): RankingEntry[] {
        let localRanking = this.cloneRanking()

        localRanking = this.sortByField(
            `final_score_judge_${this.getJudgeIndex(judgeId)}`,
            localRanking,
            "desc"
        )

        localRanking.map((rankingEntry: RankingEntry, i: number) =>
            Object.assign(rankingEntry, { rank: i + 1 })
        )

        return localRanking
    }

    getJudgeRankingWithPotentialPosition(
        judgeId: string,
        score: number,
        athleteName: string,
        round: number
    ) {
        const judgeIndex = this.getJudgeIndex(judgeId)

        let localRanking = this.cloneRanking()

        let rankingEntry = localRanking.find(
            (rankingEntry) => rankingEntry.name === athleteName
        )
        if (!rankingEntry) return localRanking

        // @ts-ignore
        rankingEntry[`round_${round}_judge_${judgeIndex}_partial_score`] = score

        let judgeScores = []
        for (let i = 0; i < round; i++) {
            judgeScores.push(
                // @ts-ignore
                rankingEntry[`round_${i + 1}_judge_${judgeIndex}_partial_score`]
            )
        }

        // @ts-ignore
        rankingEntry[`final_score_judge_${judgeIndex}`] = Math.max(
            ...judgeScores
        )
        rankingEntry["final_score"] = Math.max(...judgeScores)

        localRanking = this.sortByField(
            `final_score_judge_${judgeIndex}`,
            localRanking,
            "desc"
        )

        localRanking.map((rankingEntry: RankingEntry, i: number) =>
            Object.assign(rankingEntry, { rank: i + 1 })
        )

        return localRanking
    }

    sortByField(
        fieldName: string,
        ranking: RankingEntry[],
        method: string = "asc"
    ): RankingEntry[] {
        const customScores = "DNSDNFDSQ"
        let fieldsToCheck: string[] = []
        for (
            let roundNumber = 1;
            roundNumber < this.getRoundAmount() + 1;
            roundNumber++
        ) {
            fieldsToCheck.push(`round_${roundNumber}_score`)
        }

        const isSpecial = (r: RankingEntry) => {
            if (
                r.final_score !== null &&
                !customScores.includes(r.final_score?.toString())
            )
                return false

            return fieldsToCheck.some((field) =>
                customScores.includes(r[field]?.toString())
            )
        }

        return ranking.sort((a: RankingEntry, b: RankingEntry) => {
            if (isSpecial(a) && isSpecial(b)) return 0
            else if (isSpecial(a)) return 1
            else if (isSpecial(b)) return -1
            else if (a.final_score === null && b.final_score === null) {
                return a.order_number - b.order_number
            } else if (a.final_score === null) return 1
            else if (b.final_score === null) return -1

            if (method === "asc") {
                return Number(a[fieldName]) - Number(b[fieldName])
            } else {
                return Number(b[fieldName]) - Number(a[fieldName])
            }
        })
    }

    getRoundAmount(): number {
        if (this.ranking.length === 0) {
            return 0
        }
        for (let i = 0; i < 10; i++) {
            if (!(`round_${i + 1}_score` in this.ranking[0])) {
                return i
            }
        }

        return 0
    }

    getJudgeNumber(): number {
        if (this.ranking.length === 0) {
            return 0
        }
        for (let i = 0; i < 10; i++) {
            if (!(`round_1_judge_${i + 1}_partial_score` in this.ranking[0])) {
                return i
            }
        }

        return 0
    }

    getJudgeIndex(judgeId: string): number {
        return this.judges.indexOf(judgeId) + 1
    }

    private cloneRanking(): RankingEntry[] {
        return this.ranking.map((r) => ({ ...r }))
    }
}
