import { forwardRef, ReactElement, Ref, useState } from "react"

import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogTitle, IconButton, Slide } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function useDialog(props: { title: string }) {
    const [open, setOpen] = useState<boolean>(false)
    const handleClose = () => {
        setOpen(false)
    }

    const DialogForm = ({ children }: { children: JSX.Element }) => (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
                    {props.title}
                    <IconButton
                        sx={{ ml: "auto" }}
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {children}
            </Dialog>
        </>
    )

    return { DialogForm, setOpen, open }
}
