import { Grid } from "@mui/material"

import { ClinicExercise } from "../../../../api/app/useClinicExerciseApi"
import GenericTable from "../../../live/components/GenericTable"
import { API_MAP } from "../../constants"

export default function Ranking2o3(props: {
    clinicExerciseRanking: any
    clinicExercise: ClinicExercise
}) {
    const getFirstTableHeadersMap = () => {
        let headersMap = {
            bib: API_MAP.bib,
            original_score: API_MAP.original_score,
        }

        if (props.clinicExerciseRanking.length > 0) {
            for (let i = 1; i < 11; i++) {
                if (`panel_${i}_rank` in props.clinicExerciseRanking[0]) {
                    // @ts-ignore
                    headersMap[`panel_${i}_score`] = `P${i} score`
                }
            }
        }

        return headersMap
    }

    const getSecondTableHeadersMap = () => {
        let headersMap = {
            bib: API_MAP.bib,
        }

        if (props.clinicExerciseRanking.length > 0) {
            for (let i = 1; i < 11; i++) {
                if (`panel_${i}_rank` in props.clinicExerciseRanking[0]) {
                    // @ts-ignore
                    headersMap[`panel_${i}_rank`] = `P${i} rank`
                    // @ts-ignore
                    headersMap[`panel_${i}_final_score`] = `P${i} total`
                }
            }
        }

        return headersMap
    }

    return (
        <>
            <Grid item xs={12} sx={{ pb: 5 }}>
                {props.clinicExerciseRanking && (
                    <GenericTable
                        tableId={`ranking-${props.clinicExercise.id}-page`}
                        rowId={"athlete_id"}
                        headersMap={getFirstTableHeadersMap()}
                        rows={props.clinicExerciseRanking}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {props.clinicExerciseRanking && (
                    <GenericTable
                        tableId={`ranking-${props.clinicExercise.id}-page`}
                        rowId={"athlete_id"}
                        headersMap={getSecondTableHeadersMap()}
                        rows={props.clinicExerciseRanking.filter(
                            (row: any) => row?.round_number === "1"
                        )}
                    />
                )}
            </Grid>
        </>
    )
}
