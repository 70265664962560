import React from "react"
import { useParams } from "react-router-dom"

import { Typography, Paper, Box, Grid, Button, Stack } from "@mui/material"

import useRankingApi from "../../../api/app/useRankingApi"
import useStageApi from "../../../api/app/useStageApi"
import useExcel from "../../../hooks/useExcel"
import LoadingLayout from "../../../layouts/LoadingLayout"
import { LiveManager } from "../../live/LiveManager"
import GenericTable from "../../live/components/GenericTable"
import { API_MAP } from "../constants"
import BackButton from "../master/components/BackButton"

export default function RankingPage() {
    const { stageId } = useParams()

    const { isStageLoading, stageError, stage } = useStageApi({
        stageId: stageId!,
        enabled: Boolean(stageId),
    })

    const { isRankingLoading, rankingError, ranking } = useRankingApi({
        stageId: stageId!,
        enabled: Boolean(stage),
    })

    const liveManager =
        stage && ranking
            ? new LiveManager(ranking, stage?.judges.individuals, stage?.form)
            : undefined

    const getHeadersMap = (liveManager: LiveManager) => {
        let headersMap = {
            rank: API_MAP.rank,
            bib: API_MAP.bib,
            fis_code: API_MAP.fis_code,
            name: API_MAP.name,
            nsa_code: API_MAP.nsa_code,
            yb: API_MAP.yb,
        }

        for (
            let roundNumber = 1;
            roundNumber < liveManager.getRoundAmount() + 1;
            roundNumber++
        ) {
            let roundMap = {}
            for (
                let judgeNumber = 1;
                judgeNumber < liveManager.getJudgeNumber() + 1;
                judgeNumber++
            ) {
                // @ts-ignore
                roundMap[
                    `round_${roundNumber}_judge_${judgeNumber}_partial_score`
                ] = `R${roundNumber} J${judgeNumber}`
            }
            if (liveManager.stageFormat === "2z3") {
                // @ts-ignore
                roundMap[`round_${roundNumber}_trick_id`] =
                    `R${roundNumber} ${API_MAP.trick_id}`
            }
            // @ts-ignore
            roundMap[`round_${roundNumber}_score`] =
                `R${roundNumber} ${API_MAP.score}`

            Object.assign(headersMap, roundMap)
        }

        // @ts-ignore
        headersMap["final_score"] = API_MAP.final_score

        return headersMap
    }

    const { downloadExcel } = useExcel()

    if (isRankingLoading || isStageLoading) return <LoadingLayout />

    if (rankingError || stageError) return "An error has occurred: "

    return (
        <>
            {stage && liveManager && (
                <Grid container>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <BackButton />
                        <Paper
                            component={Stack}
                            direction="column"
                            justifyContent="center"
                            sx={{ boxShadow: "none" }}
                        >
                            <Box sx={{ position: "absolute" }}>
                                <Button
                                    variant={"outlined"}
                                    onClick={() => {
                                        downloadExcel(
                                            "ranking-all-list",
                                            liveManager.getSortedRanking(),
                                            getHeadersMap(liveManager)
                                        )
                                    }}
                                    size={"small"}
                                >
                                    Download All
                                </Button>
                                <Button
                                    variant={"outlined"}
                                    onClick={() => {
                                        downloadExcel(
                                            "ranking-fis-list",
                                            liveManager.getSortedFISRanking(),
                                            getHeadersMap(liveManager)
                                        )
                                    }}
                                    size={"small"}
                                    sx={{ ml: 1 }}
                                >
                                    Download FIS
                                </Button>
                            </Box>
                            <Typography variant={"h5"} align={"center"}>
                                Ranking List
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <GenericTable
                            tableId={`ranking-${stageId}-page`}
                            rowId={"athlete_id"}
                            headersMap={getHeadersMap(liveManager)}
                            rows={liveManager.getSortedRanking()}
                            redHorizontal={stage.athletes_for_next_stage - 1}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    )
}
