import { useContext } from "react"
import { useMutation } from "react-query"

import { BACKEND_URL } from "../../config"
import { NotificationContext } from "../../hooks/useNotification"
import { UserDataContext } from "../../pages/app/UserDataProvider"
import { prepareError } from "../utils"

interface useAddScoreApiProps {
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useAddScoreApi(props: useAddScoreApiProps) {
    const { token, authRedirect } = useContext(UserDataContext)!
    const { updateNotification } = useContext(NotificationContext)!

    const addScore = useMutation({
        mutationFn: ({
            runId,
            scores,
            trickId = undefined,
        }: {
            runId: string
            scores: { [key: string]: number }
            trickId: string | undefined
        }) =>
            fetch(`${BACKEND_URL}/api/events/runs/${runId}/add-score/`, {
                method: "POST",
                headers: {
                    Authorization: token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    partial_scores: Object.entries(scores).map(
                        ([judge, score]) => ({
                            judge,
                            partial_score: score,
                        })
                    ),
                    trick_id: trickId,
                }),
            }).then((res) => {
                if (res.status === 401 || res.status === 403) {
                    authRedirect()
                } else if (!res.ok) {
                    return prepareError(res)
                }
            }),
        onSuccess: (data) => props.onSuccess && props.onSuccess(data),
        onError: (err) => {
            updateNotification({
                open: true,
                severity: "error",
                text: err?.toString(),
            })
            props.onError && props.onError(err)
        },
    })

    return {
        addScore,
    }
}
