import { BACKEND_URL } from "../../config"
import useApi from "./useApi"

export interface Judge {
    user_id: string
    full_name: string
}

interface useJudgeListProps {
    competitionId: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useJudgesApi(props: useJudgeListProps) {
    const {
        isLoading: isJudgesLoading,
        refetch: refetchJudges,
        error: judgesError,
        data: judges,
    } = useApi<Judge[]>({
        url: `${BACKEND_URL}/api/events/judges/?competition_id=${props.competitionId}`,
        enabled: props.enabled,
        onSuccess: props.onSuccess,
        onError: props.onError,
    })

    return {
        isJudgesLoading,
        judgesError,
        refetchJudges,
        judges,
    }
}
