import { useContext } from "react"

import { BACKEND_URL } from "../../config"
import { NotificationContext } from "../../hooks/useNotification"
import useApi from "./useApi"

interface useClinicExerciseRankingApiProps {
    clinicExerciseId: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useClinicExerciseRankingApi(
    props: useClinicExerciseRankingApiProps
) {
    const { updateNotification } = useContext(NotificationContext)!
    const {
        isLoading: isClinicExerciseRankingLoading,
        error: clinicExerciseRankingError,
        refetch: refetchClinicExerciseRanking,
        data: clinicExerciseRanking,
    } = useApi<any>({
        url: `${BACKEND_URL}/api/clinics/clinic-exercises/${props.clinicExerciseId}/ranking/`,
        enabled: props.enabled,
        onSuccess: (data) => props.onSuccess && props.onSuccess(data),
        onError: (err) => {
            updateNotification({
                open: true,
                severity: "error",
                text: err?.toString(),
            })
            props.onError && props.onError(err)
        },
    })

    return {
        isClinicExerciseRankingLoading,
        clinicExerciseRankingError,
        refetchClinicExerciseRanking,
        clinicExerciseRanking,
    }
}
