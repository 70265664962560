import { useNavigate } from "react-router-dom"

import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from "@mui/material"

import useClinicsApi from "../../../api/app/useClinicsApi"
import LoadingLayout from "../../../layouts/LoadingLayout"

export default function Clinics() {
    const navigate = useNavigate()
    const { isClinicsLoading, clinicsError, clinics } = useClinicsApi({
        enabled: true,
    })

    if (isClinicsLoading) return <LoadingLayout />

    if (clinicsError) return "An error has occurred: " + clinicsError

    return (
        <Grid
            container
            sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Grid item xs={12} md={6}>
                {clinics &&
                    clinics.map((clinic) => (
                        <Card key={clinic.id} sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                >
                                    {clinic.name}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    size="small"
                                    onClick={() =>
                                        navigate(`/app/clinics/${clinic.id}`)
                                    }
                                >
                                    Enter
                                </Button>
                            </CardActions>
                        </Card>
                    ))}
            </Grid>
        </Grid>
    )
}
