import { Container } from "@mui/material"

import TopBar from "./components/TopBar"

export default function PrimaryLayout({ children }: { children: JSX.Element }) {
    return (
        <>
            <TopBar />
            <Container
                sx={{ px: { xs: "5px", md: "16px" }, my: { xs: 2, md: 5 } }}
                maxWidth={false}
            >
                {children}
            </Container>
        </>
    )
}
