import { Fragment } from "react"
import { useNavigate, useParams } from "react-router-dom"

import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from "@mui/material"

import useClinicExercisesApi from "../../../api/app/useClinicExercisesApi"
import useManageClinicExerciseApi from "../../../api/app/useManageClinicExerciseApi"
import useConfirmDialog from "../../../hooks/useConfirmDialog"
import LoadingLayout from "../../../layouts/LoadingLayout"
import BackButton from "./components/BackButton"

export default function Clinic() {
    const navigate = useNavigate()
    const { clinicId } = useParams()

    const { ConfirmDialog, confirm } = useConfirmDialog()

    const {
        isClinicExercisesLoading,
        clinicExercisesError,
        clinicExercises,
        refetchClinicExercises,
    } = useClinicExercisesApi({
        clinicId: clinicId!,
        enabled: Boolean(clinicId),
    })

    const { manageClinicExercise } = useManageClinicExerciseApi({
        onSuccess: (data) => refetchClinicExercises(),
    })

    const getStatus = (status: string) => {
        switch (status) {
            case "n":
                return "Not started"
            case "e":
                return "Ended"
            case "s":
                return "Started"
            default:
                return ""
        }
    }

    const getColor = (status: string) => {
        switch (status) {
            case "n":
                return "#AAD2EC"
            case "e":
                return "#F69C9E"
            case "s":
                return "#97E3C2"
            default:
                return ""
        }
    }

    if (isClinicExercisesLoading) return <LoadingLayout />

    if (clinicExercisesError)
        return "An error has occurred: " + clinicExercisesError

    return (
        <Grid
            container
            sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Grid item xs={12} md={6}>
                <BackButton />
                <ConfirmDialog />
                {clinicExercises && (
                    <>
                        {clinicExercises.map((clinicExercise) => (
                            <Card
                                key={clinicExercise.id}
                                sx={{
                                    mb: 2,
                                    borderTop: 3,
                                    borderColor: getColor(
                                        clinicExercise.status
                                    ),
                                }}
                            >
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                    >
                                        {clinicExercise.competition_data.name}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        fontWeight={"bold"}
                                    >
                                        Status:{" "}
                                        {getStatus(clinicExercise.status)}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    {clinicExercise.status !== "n" && (
                                        <Button
                                            onClick={() =>
                                                navigate(
                                                    `/app/exercises/${clinicExercise.id}`
                                                )
                                            }
                                        >
                                            Ranking
                                        </Button>
                                    )}
                                    {clinicExercise.status === "n" && (
                                        <Button
                                            onClick={() =>
                                                confirm(
                                                    "Are you sure?",
                                                    <Fragment>
                                                        All current started
                                                        exercises will be
                                                        closed. You will not be
                                                        able to revert this
                                                        action.
                                                    </Fragment>,
                                                    () =>
                                                        manageClinicExercise.mutate(
                                                            {
                                                                manage: "start",
                                                                clinicExerciseId:
                                                                    clinicExercise.id,
                                                            }
                                                        )
                                                )
                                            }
                                        >
                                            Start
                                        </Button>
                                    )}

                                    {clinicExercise.status === "s" && (
                                        <Button
                                            onClick={() =>
                                                confirm(
                                                    "Are you sure?",
                                                    <Fragment>
                                                        You will not be able to
                                                        revert this action.
                                                    </Fragment>,
                                                    () =>
                                                        manageClinicExercise.mutate(
                                                            {
                                                                manage: "end",
                                                                clinicExerciseId:
                                                                    clinicExercise.id,
                                                            }
                                                        )
                                                )
                                            }
                                        >
                                            End
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        ))}
                    </>
                )}
            </Grid>
        </Grid>
    )
}
