import { BACKEND_URL } from "../../config"
import useApi from "./useApi"

export interface Stage {
    athletes_for_next_stage: number
    competition: string
    date: string
    form: string
    id: string
    name: string
    only_fis_for_next_stage?: boolean
    round_amount: number
    status: string
}

interface useCurrentStageApiProps {
    competitionId: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useCurrentStageApi(props: useCurrentStageApiProps) {
    const {
        isLoading: isStageLoading,
        refetch: refetchStage,
        error: stageError,
        data: stage,
    } = useApi<Stage | undefined>({
        url: `${BACKEND_URL}/api/events/competitions/${props.competitionId}/current_stage/`,
        enabled: props.enabled,
        onSuccess: props.onSuccess,
        onError: props.onError,
    })

    return { isStageLoading, refetchStage, stageError, stage }
}
