import React, { useContext, useEffect, useState } from "react"

import {
    Box,
    Card,
    CardContent,
    Grid,
    Tab,
    Tabs,
    Typography,
} from "@mui/material"

import usePhoneLockUnlock from "../../../hooks/usePhoneLockUnlock"
import { UserDataContext } from "../../app/UserDataProvider"
import { Namespaces, WebSocketContext } from "../../app/WebSocketProvider"
import BackButton from "../../app/master/components/BackButton"
import { LiveContext } from "../LiveStageWrapper"
import IndividualSummaries from "./IndividualSummaries"
import ProposeScore from "./components/ProposeScore"

export default function IndividualJudgeLiveCompetition() {
    const { setJudgePosition } = useContext(UserDataContext)!

    const { competition, stage, setSignal, run, userData, liveManager } =
        useContext(LiveContext)!

    const { addWebSocketHandler, removeWebSocketHandler } =
        useContext(WebSocketContext)!

    const [score, setScore] = useState<any>(undefined)
    useEffect(() => {
        if (competition) {
            const handlerId = `${Namespaces.Competition}:${competition.id}:individual-judge`
            addWebSocketHandler(handlerId, (payload: any) => {
                if (payload && payload?.type === "switch_athlete") {
                    setScore(undefined)
                    setSignal("scored")
                }
                if (payload && payload?.type === "reload") {
                    setSignal("reload")
                }
            })
            return () => removeWebSocketHandler(handlerId)
        }
    }, [addWebSocketHandler, removeWebSocketHandler, competition, setSignal])

    usePhoneLockUnlock(() => {
        setSignal("reload")
    })

    useEffect(() => {
        if (liveManager) {
            setJudgePosition(liveManager.getJudgeIndex(userData.id).toString())
        }
        return () => {
            setJudgePosition(undefined)
        }
    }, [])

    return (
        <Grid container>
            <Grid item xs={12}>
                <BackButton />
            </Grid>
            <Grid item xs={12} md={7}>
                <IndividualSummaries
                    judgeId={userData.id}
                    liveManager={liveManager}
                    score={score}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={5}
                sx={{
                    pl: { xs: 0, md: "20px" },
                    pt: { xs: "20px", md: 0 },
                }}
            >
                <Tabs
                    value={0}
                    sx={{ mb: 1, display: { xs: "none", md: "block" } }}
                >
                    <Tab label={"Athlete"} />
                </Tabs>
                {run ? (
                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="h5">{run.name}</Typography>
                            <Typography variant="body2" color="text.secondary">
                                Category: {competition.category}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Stage: {stage.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Run: {run.round_number}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Start Number: {run.order_number}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Bib: {run.bib}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Stance: {run.stance}
                            </Typography>
                        </CardContent>
                    </Card>
                ) : (
                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography>No next athlete</Typography>
                        </CardContent>
                    </Card>
                )}
                <Box sx={{ mt: { xs: "20px", md: 0 } }}>
                    <ProposeScore setScore={setScore} />
                </Box>
            </Grid>
        </Grid>
    )
}
