import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"

import { Authenticator } from "@aws-amplify/ui-react"

import AppRoutes from "./AppRoutes"

const queryClient = new QueryClient()

function App() {
    return (
        <>
            <Authenticator.Provider>
                <QueryClientProvider client={queryClient}>
                    <AppRoutes />
                </QueryClientProvider>
            </Authenticator.Provider>
        </>
    )
}

export default App
