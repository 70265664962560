import useNotification, {
    NotificationContext,
} from "../../hooks/useNotification"
import PrimaryLayout from "../../layouts/PrimaryLayout"
import UserDataProvider from "./UserDataProvider"
import WebSocketProvider from "./WebSocketProvider"

export default function AppWrapper({ children }: { children: JSX.Element }) {
    const { Notification, updateNotification } = useNotification()

    return (
        <>
            <Notification />
            <UserDataProvider>
                <WebSocketProvider>
                    <NotificationContext.Provider
                        value={{ updateNotification }}
                    >
                        <PrimaryLayout>{children}</PrimaryLayout>
                    </NotificationContext.Provider>
                </WebSocketProvider>
            </UserDataProvider>
        </>
    )
}
