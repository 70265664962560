import { Fragment, useContext, useEffect, useState } from "react"

import DeleteIcon from "@mui/icons-material/Delete"
import NorthIcon from "@mui/icons-material/North"
import SouthIcon from "@mui/icons-material/South"
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    IconButton,
    Paper,
    Typography,
} from "@mui/material"

import useJudgesApi, { Judge } from "../../../api/app/useJudgesApi"
import useSaveJudgesApi from "../../../api/app/useSaveJudgesApi"
import useConfirmDialog from "../../../hooks/useConfirmDialog"
import { NotificationContext } from "../../../hooks/useNotification"
import LoadingLayout from "../../../layouts/LoadingLayout"

export default function JudgeEditDialog(props: {
    DialogForm: JSX.Element
    competitionId: string
    open: boolean
}) {
    const { updateNotification } = useContext(NotificationContext)!

    const [localJudges, setLocalJudges] = useState<Judge[] | undefined>(
        undefined
    )
    const { isJudgesLoading, judgesError, judges } = useJudgesApi({
        competitionId: props.competitionId,
        enabled: props.open,
    })
    const { saveJudges } = useSaveJudgesApi({
        onError: (error) => {
            updateNotification({
                open: true,
                severity: "error",
                text: error?.toString(),
            })
        },
        onSuccess: (data) => {
            updateNotification({
                open: true,
                severity: "success",
                text: "Judges saved.",
            })
        },
    })

    const { ConfirmDialog, confirm } = useConfirmDialog()

    useEffect(() => {
        if (judges) {
            setLocalJudges(judges)
        }
    }, [judges])
    if (isJudgesLoading) return <LoadingLayout />

    if (judgesError) return "An error has occurred: " + judgesError

    const switchPosition = (currPos: number, pos: number) => {
        let tmpJudges = JSON.parse(JSON.stringify(localJudges))

        let temp = tmpJudges![currPos + pos]

        tmpJudges![currPos + pos] = tmpJudges![currPos]
        tmpJudges![currPos] = temp

        setLocalJudges(tmpJudges)
    }

    const removeJudge = (pos: number) => {
        let tmpJudges = JSON.parse(JSON.stringify(localJudges))

        tmpJudges = tmpJudges.filter(
            (judge: string) => judge !== tmpJudges[pos]
        )

        setLocalJudges(tmpJudges)
    }

    return (
        // @ts-ignore
        <props.DialogForm>
            <ConfirmDialog />
            {localJudges && (
                <Paper sx={{ p: 5, py: 0 }}>
                    <DialogContent>
                        {localJudges.map((judge, i) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    py: 2,
                                    borderBottom: 1,
                                    "&:last-child": {
                                        pb: 0,
                                        borderBottom: 0,
                                    },
                                    "&:first-child": { pt: 0 },
                                }}
                                key={i}
                            >
                                <Typography sx={{ pr: 3 }}>
                                    J{i + 1} - {judge.full_name}
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: {
                                            xs: "column",
                                            md: "row",
                                        },
                                    }}
                                >
                                    {i !== 0 && (
                                        <IconButton
                                            size={"small"}
                                            onClick={() =>
                                                switchPosition(i, -1)
                                            }
                                        >
                                            <NorthIcon />
                                        </IconButton>
                                    )}
                                    {i !== localJudges!.length - 1 && (
                                        <IconButton
                                            size={"small"}
                                            onClick={() => switchPosition(i, 1)}
                                        >
                                            <SouthIcon />
                                        </IconButton>
                                    )}

                                    <IconButton
                                        size={"small"}
                                        onClick={() => removeJudge(i)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                    </DialogContent>
                    <DialogActions sx={{ pb: 2 }}>
                        <Button
                            onClick={() =>
                                confirm(
                                    "Are you sure?",
                                    <Fragment>
                                        <Typography>
                                            If you removed a judge, it is
                                            irreversible
                                        </Typography>
                                    </Fragment>,
                                    () =>
                                        saveJudges.mutate({
                                            competitionId: props.competitionId,
                                            userIds: localJudges.map(
                                                (judge) => judge.user_id
                                            ),
                                        })
                                )
                            }
                        >
                            Save Changes
                        </Button>
                    </DialogActions>
                </Paper>
            )}
        </props.DialogForm>
    )
}
