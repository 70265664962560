import React from "react"
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from "react-router-dom"

import { useAuthenticator } from "@aws-amplify/ui-react"

import LoginPage from "./pages/LoginPage"
import AppWrapper from "./pages/app/AppWrapper"
import CompetitionPage from "./pages/app/head/CompetitionPage"
import Events from "./pages/app/head/Events"
import QualifiedListPage from "./pages/app/head/QualifiedListPage"
import RankingPage from "./pages/app/head/RankingPage"
import RunsPage from "./pages/app/head/RunsPage"
import Clinic from "./pages/app/master/Clinic"
import ClinicExercise from "./pages/app/master/ClinicExercise"
import Clinics from "./pages/app/master/Clinics"
import LiveStageWrapper from "./pages/live/LiveStageWrapper"
import PublicEvents from "./pages/public/PublicEvents"
import PublicRanking from "./pages/public/PublicRanking"

export default function AppRoutes() {
    const { user } = useAuthenticator((context) => [context.user])

    return (
        <Router>
            <Routes>
                <Route
                    path="/public/competitions/:competitionId"
                    element={<PublicRanking />}
                />
                <Route path="/public/events" element={<PublicEvents />} />
                {user ? (
                    <>
                        <Route
                            path="/app"
                            element={
                                <AppWrapper>
                                    <Events />
                                </AppWrapper>
                            }
                        />
                        <Route
                            path="/app/clinics"
                            element={
                                <AppWrapper>
                                    <Clinics />
                                </AppWrapper>
                            }
                        />
                        <Route
                            path="/app/clinics/:clinicId"
                            element={
                                <AppWrapper>
                                    <Clinic />
                                </AppWrapper>
                            }
                        />
                        <Route
                            path="/app/exercises/:clinicExerciseId"
                            element={
                                <AppWrapper>
                                    <ClinicExercise />
                                </AppWrapper>
                            }
                        />
                        <Route
                            path="/app/competitions/:competitionId/live"
                            element={
                                <AppWrapper>
                                    <LiveStageWrapper />
                                </AppWrapper>
                            }
                        />
                        <Route
                            path="/app/competitions/:competitionId"
                            element={
                                <AppWrapper>
                                    <CompetitionPage />
                                </AppWrapper>
                            }
                        />
                        <Route
                            path="/app/competitions/:competitionId/candidates"
                            element={
                                <AppWrapper>
                                    <QualifiedListPage initial={false} />
                                </AppWrapper>
                            }
                        />
                        <Route
                            path="/app/competitions/:competitionId/initial-candidates"
                            element={
                                <AppWrapper>
                                    <QualifiedListPage initial={true} />
                                </AppWrapper>
                            }
                        />
                        <Route
                            path="/app/stages/:stageId/ranking"
                            element={
                                <AppWrapper>
                                    <RankingPage />
                                </AppWrapper>
                            }
                        />
                        <Route
                            path="/app/stages/:stageId/runs"
                            element={
                                <AppWrapper>
                                    <RunsPage />
                                </AppWrapper>
                            }
                        />
                    </>
                ) : (
                    <Route path="*" element={<Navigate to="/" />} />
                )}
                <Route exact path="/" element={<LoginPage />} />
            </Routes>
        </Router>
    )
}
