import { ControllerRenderProps, FieldError, RefCallBack } from "react-hook-form"

import { TextField } from "@mui/material"

interface ScoreFieldProps {
    field: ControllerRenderProps
    invalid: boolean
    error: FieldError | undefined
    label: string
    onEnter: () => void
}
export default function ScoreField(props: ScoreFieldProps) {
    const validateScore = (e: any) => {
        let nextChar = e.key
        let input = e.target as HTMLInputElement
        let start = input.selectionStart || 0
        let end = input.selectionEnd || 0

        let newValue =
            input.value.substring(0, start) +
            nextChar +
            input.value.substring(end)

        if (!/^([0-9]|[1-9][0-9]|100)$/.test(newValue)) {
            e.preventDefault()
        }
    }

    return (
        <TextField
            {...props.field}
            inputRef={props.field.ref}
            variant="outlined"
            fullWidth
            helperText={props.error?.message}
            error={props.invalid}
            label={`${props.label}`}
            size={"small"}
            sx={{ mr: 2, width: "200px", mb: 2 }}
            InputLabelProps={{ shrink: true }}
            onKeyPress={validateScore}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    e.stopPropagation()
                    props.onEnter()
                }
            }}
            inputProps={{ inputMode: "numeric" }}
        />
    )
}
