import React, {
    Dispatch,
    Fragment,
    SetStateAction,
    useContext,
    useEffect,
} from "react"
import { Controller, useForm } from "react-hook-form"
import { useHotkeys } from "react-hotkeys-hook"

import {
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
} from "@mui/material"

import { IS_PROD } from "../../../../config"
import useConfirmDialog from "../../../../hooks/useConfirmDialog"
import { Namespaces, WebSocketContext } from "../../../app/WebSocketProvider"
import { LiveContext } from "../../LiveStageWrapper"
import ScoreField from "../../components/ScoreField"

interface ConfirmScoreProps {
    setScores: Dispatch<SetStateAction<{ [key: string]: number }>>
}

export default function ConfirmScore({ setScores }: ConfirmScoreProps) {
    const { ConfirmDialog, confirm } = useConfirmDialog()

    const { competition, run, addScore, addCustomScore, setSignal } =
        useContext(LiveContext)!

    const { addWebSocketHandler, removeWebSocketHandler } =
        useContext(WebSocketContext)!

    const judges: string[] = competition.judges.individuals
    const judgeAmount: number = competition.judges.individuals.length
    const labeledJudges = judges.map((judgeId, index) => ({
        judgeId,
        label: `J${index + 1}`,
    }))

    type Inputs = {
        [key: string]: number
    }
    const { handleSubmit, control, reset, watch, resetField } =
        useForm<Inputs>()

    useEffect(() => {
        if (competition) {
            const handlerId = `${Namespaces.Competition}:${competition.id}:confirm-score`
            addWebSocketHandler(handlerId, (payload: any) => {
                if (!payload || payload?.type !== "add_note") return
                if ("judge_id" in payload && "value" in payload) {
                    const labeledJudge = labeledJudges.find(
                        (labeledJudge) =>
                            labeledJudge.judgeId === payload.judge_id
                    )
                    if (!labeledJudge) return

                    // @ts-ignore
                    resetField(labeledJudge.label, {
                        defaultValue: Number(payload.value),
                    })
                }
            })
            return () => removeWebSocketHandler(handlerId)
        }
    }, [
        addWebSocketHandler,
        removeWebSocketHandler,
        labeledJudges,
        resetField,
        competition,
    ])

    useEffect(() => {
        let timer: null | ReturnType<typeof setTimeout> = null
        const subscription = watch((data) => {
            if (timer) clearTimeout(timer)

            timer = setTimeout(() => {
                // [payload.judge_id]: Number(payload.value)
                let newScores = {}
                for (const [k, v] of Object.entries(data)) {
                    if (
                        v === undefined ||
                        (typeof v === "number" && v === 0) ||
                        (typeof v === "string" && (v === "0" || v === ""))
                    ) {
                        continue
                    } else {
                        const labeledJudge = labeledJudges.find(
                            (labeledJudge) => labeledJudge.label === k
                        )
                        if (!labeledJudge) continue
                        // @ts-ignore
                        newScores[labeledJudge.judgeId] = Number(v)
                    }
                }
                setScores(newScores)
            }, 700)
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const submitScore = (scores: Inputs) => {
        if (run) {
            if (Object.entries(scores).length !== judgeAmount) {
                alert("za malo ocen!")
                return
            }

            let convertedScores = {}

            for (const [k, v] of Object.entries(scores)) {
                const labeledJudge = labeledJudges.find(
                    (labeledJudge) => labeledJudge.label === k
                )!
                // @ts-ignore
                convertedScores[labeledJudge.judgeId] = v
            }

            addScore.mutate(
                {
                    runId: run.id,
                    scores: convertedScores,
                },
                {
                    onSuccess: () => {
                        let resetScores: { [key: string]: string } = {}
                        for (const labeledJudge of labeledJudges) {
                            resetScores[labeledJudge.label] = ""
                        }
                        // @ts-ignore
                        reset(resetScores)
                        setScores({})
                        setSignal("scored")
                    },
                }
            )
        }
    }

    useHotkeys(
        "enter",
        (e) => {
            onEnter()
        },
        []
    )

    const onEnter = () => {
        handleSubmit(submitScore)()
    }

    const submitCustomScore = (customScore: "DNF" | "DNS" | "DSQ" | string) => {
        if (run) {
            addCustomScore.mutate(
                {
                    runId: run.id,
                    customScore: customScore,
                },
                {
                    onSuccess: () => {
                        setSignal("scored")
                    },
                }
            )
        }
    }

    const addFakeScores = () => {
        let newScores: { [key: string]: number } = {}
        for (const labeledJudge of labeledJudges) {
            newScores[labeledJudge.label] = Math.floor(Math.random() * 100) + 1
        }
        reset(newScores)
    }

    return (
        <>
            {run && (
                <>
                    <ConfirmDialog />
                    <Card>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                            >
                                Scores
                            </Typography>
                            {labeledJudges.map((labeledJudge) => (
                                <Controller
                                    // @ts-ignore
                                    name={labeledJudge.label}
                                    rules={{
                                        required: "This field is mandatory.",
                                        min: {
                                            value: 0,
                                            message:
                                                "Value should be greater than or equal to 0",
                                        },
                                        max: {
                                            value: 100,
                                            message:
                                                "Value should be less than or equal to 100",
                                        },
                                    }}
                                    control={control}
                                    render={({
                                        field: { ...field },
                                        fieldState: { invalid, error },
                                    }) => (
                                        <ScoreField
                                            field={field}
                                            error={error}
                                            invalid={invalid}
                                            label={`${labeledJudge.label} Score`}
                                            onEnter={onEnter}
                                        />
                                    )}
                                />
                            ))}
                        </CardContent>
                        <CardActions>
                            <Button
                                onClick={handleSubmit(submitScore)}
                                size={"large"}
                                color={"success"}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.stopPropagation()
                                        onEnter()
                                    }
                                }}
                            >
                                Confirm score
                            </Button>

                            <Button
                                onClick={() =>
                                    confirm(
                                        "Are you sure?",
                                        <Fragment>
                                            <Typography>
                                                You will assign DNF to athlete.
                                            </Typography>
                                        </Fragment>,
                                        () => submitCustomScore("DNF")
                                    )
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.stopPropagation()
                                        onEnter()
                                    }
                                }}
                            >
                                DNF
                            </Button>
                            <Button
                                onClick={() =>
                                    confirm(
                                        "Are you sure?",
                                        <Fragment>
                                            <Typography>
                                                You will assign DNS to athlete.
                                            </Typography>
                                        </Fragment>,
                                        () => submitCustomScore("DNS")
                                    )
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.stopPropagation()
                                        onEnter()
                                    }
                                }}
                            >
                                DNS
                            </Button>
                            <Button
                                onClick={() =>
                                    confirm(
                                        "Are you sure?",
                                        <Fragment>
                                            <Typography>
                                                You will assign DSQ to athlete.
                                            </Typography>
                                        </Fragment>,
                                        () => submitCustomScore("DSQ")
                                    )
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.stopPropagation()
                                        onEnter()
                                    }
                                }}
                            >
                                DSQ
                            </Button>

                            {!IS_PROD && (
                                <>
                                    <span>||||||||||</span>
                                    <Button
                                        onClick={() => addFakeScores()}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                e.stopPropagation()
                                                onEnter()
                                            }
                                        }}
                                    >
                                        Add fake score
                                    </Button>
                                </>
                            )}
                        </CardActions>
                    </Card>
                </>
            )}
        </>
    )
}
