import { useContext } from "react"

import { API_MAP } from "../../app/constants"
import { LiveManager } from "../LiveManager"
import { LiveContext } from "../LiveStageWrapper"
import GenericTable from "./GenericTable"

interface RankingListProps {
    liveManager: LiveManager
    scores?: { [key: string]: number }
    trickId?: string
}

export default function RankingList({
    liveManager,
    scores,
    trickId,
}: RankingListProps) {
    const { run, stage } = useContext(LiveContext)!

    const showPositionInRanking = () => {
        if (!run) return false
        if (!scores) return false
        if (stage.form === "2z3") {
            return (
                Object.entries(scores).length === liveManager.judges.length &&
                trickId !== ""
            )
        } else {
            return Object.entries(scores).length === liveManager.judges.length
        }
    }

    const judgeRankingList = showPositionInRanking()
        ? liveManager.getRankingWithPotentialPosition(
              scores!,
              trickId!,
              run!.name,
              run!.round_number
          )
        : liveManager.getRanking()

    const getHighlightMap = () => {
        if (run && showPositionInRanking()) {
            const rankingEntryIndex = judgeRankingList.findIndex(
                (el) => el.name === run.name
            )
            if (rankingEntryIndex === -1) return {}
            const rankingEntry = judgeRankingList[rankingEntryIndex]

            let drawIndex = judgeRankingList.findIndex(
                (el) =>
                    el.name !== run.name &&
                    el.final_score === rankingEntry?.final_score
            )

            if (drawIndex > -1) {
                return {
                    [rankingEntryIndex]: "red",
                    [drawIndex]: "red",
                }
            } else {
                return { [rankingEntryIndex]: "yellowgreen" }
            }
        } else return {}
    }
    const getHeadersMap = () => {
        let headersMap = {
            rank: API_MAP.rank,
            bib: API_MAP.bib,
            name: API_MAP.name,
        }

        for (
            let roundNumber = 1;
            roundNumber < liveManager.getRoundAmount() + 1;
            roundNumber++
        ) {
            let roundMap = {}
            for (
                let judgeNumber = 1;
                judgeNumber < liveManager.getJudgeNumber() + 1;
                judgeNumber++
            ) {
                // @ts-ignore
                roundMap[
                    `round_${roundNumber}_judge_${judgeNumber}_partial_score`
                ] = `J${judgeNumber}`
            }
            // @ts-ignore
            roundMap[`round_${roundNumber}_score`] = API_MAP.average
            if (liveManager.stageFormat === "2z3") {
                // @ts-ignore
                roundMap[`round_${roundNumber}_trick_id`] = API_MAP.trick_id
            }

            Object.assign(headersMap, roundMap)
        }

        // @ts-ignore
        headersMap["final_score"] = API_MAP.final_score

        return headersMap
    }

    return (
        <>
            <GenericTable
                tableId={"ranking"}
                rowId={"athlete_id"}
                headersMap={getHeadersMap()}
                rows={judgeRankingList}
                highlightedMap={getHighlightMap()}
                redHorizontal={stage.athletes_for_next_stage - 1}
                bolded={[API_MAP.average]}
            />
        </>
    )
}
