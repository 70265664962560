import { useContext, useEffect, useState } from "react"

import { UserDataContext } from "../../pages/app/UserDataProvider"
import { prepareError } from "../utils"

interface useStageApiProps {
    url: string
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function useApi<T>(props: useStageApiProps) {
    const { token, authRedirect } = useContext(UserDataContext)!

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<unknown>(null)
    const [data, setData] = useState<T | undefined>(undefined)

    const fetchData = async () => {
        setIsLoading(true)
        setError(null)

        try {
            const response = await fetch(`${props.url}`, {
                headers: { Authorization: token },
            })

            if (
                response.status === 201 ||
                response.status === 202 ||
                response.status === 204
            ) {
                setData(undefined)
                if (props.onSuccess) props.onSuccess(undefined)
            } else if (response.status === 401 || response.status === 403) {
                authRedirect()
            } else if (!response.ok) {
                const error = await prepareError(response)
                setError(error)
                if (props.onError) props.onError(error)
            } else {
                const data: T = await response.json()
                setData(data)
                if (props.onSuccess) props.onSuccess(data)
            }
        } catch (error) {
            setError(error)
            if (props.onError) props.onError(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (props.enabled) {
            fetchData()
        }
    }, [props.enabled, props.url])

    return {
        isLoading,
        refetch: fetchData,
        error,
        data,
    }
}
