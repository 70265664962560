import { useContext, useEffect } from "react"
import { useParams } from "react-router-dom"

import { Grid, Typography } from "@mui/material"

import useClinicExerciseApi from "../../../api/app/useClinicExerciseApi"
import useClinicExerciseRankingApi from "../../../api/app/useClinicExerciseRankingApi"
import LoadingLayout from "../../../layouts/LoadingLayout"
import {
    MessageTypes,
    Namespaces,
    WebSocketContext,
} from "../WebSocketProvider"
import BackButton from "./components/BackButton"
import Ranking1o2 from "./components/Ranking1o2"
import Ranking2o3 from "./components/Ranking2o3"

export default function ClinicExercise() {
    const { sendMessage, addWebSocketHandler, removeWebSocketHandler } =
        useContext(WebSocketContext)!

    const { clinicExerciseId } = useParams()

    const { isClinicExerciseLoading, clinicExerciseError, clinicExercise } =
        useClinicExerciseApi({
            clinicExerciseId: clinicExerciseId!,
            enabled: Boolean(clinicExerciseId),
        })

    const {
        isClinicExerciseRankingLoading,
        clinicExerciseRankingError,
        clinicExerciseRanking,
        refetchClinicExerciseRanking,
    } = useClinicExerciseRankingApi({
        clinicExerciseId: clinicExerciseId!,
        enabled: Boolean(clinicExerciseId),
    })

    useEffect(() => {
        if (clinicExerciseId) {
            const handlerId = `${Namespaces.Exercise}:${clinicExerciseId}:refetch`
            addWebSocketHandler(handlerId, (payload: any) => {
                if (payload && payload?.type === "refetch") {
                    refetchClinicExerciseRanking()
                }
            })
            return () => removeWebSocketHandler(handlerId)
        }
    }, [
        addWebSocketHandler,
        removeWebSocketHandler,
        clinicExerciseId,
        refetchClinicExerciseRanking,
    ])

    if (clinicExerciseRankingError)
        return "An error has occurred: " + clinicExerciseRankingError

    if (clinicExerciseError)
        return "An error has occurred: " + clinicExerciseError

    if (isClinicExerciseLoading) return <LoadingLayout />

    return (
        <Grid
            container
            sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Grid item xs={12} md={8}>
                <BackButton />
                {clinicExercise && (
                    <Typography variant={"h6"}>
                        {clinicExercise.competition_data.name}
                    </Typography>
                )}
                {isClinicExerciseRankingLoading ? (
                    <LoadingLayout />
                ) : (
                    <>
                        {clinicExercise && (
                            <>
                                {clinicExercise.stage_data.form === "1z2" ? (
                                    <Ranking1o2
                                        clinicExerciseRanking={
                                            clinicExerciseRanking
                                        }
                                        clinicExercise={clinicExercise}
                                    />
                                ) : (
                                    <Ranking2o3
                                        clinicExerciseRanking={
                                            clinicExerciseRanking
                                        }
                                        clinicExercise={clinicExercise}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    )
}
