import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import { Grid, Paper, Stack, Typography } from "@mui/material"

import usePublicCurrentStageApi from "../../api/public/usePublicCurrentStageApi"
import usePublicRankingApi from "../../api/public/usePublicRankingApi"
import LoadingLayout from "../../layouts/LoadingLayout"
import PrimaryLayout from "../../layouts/PrimaryLayout"
import { API_MAP } from "../app/constants"
import { LiveManager } from "../live/LiveManager"
import GenericTable from "../live/components/GenericTable"

export default function PublicRanking() {
    const { competitionId } = useParams()

    const { isStageLoading, stageError, refetchStage, stage } =
        usePublicCurrentStageApi({
            competitionId: competitionId!,
            enabled: Boolean(competitionId),
        })

    const {
        isPublicRankingLoading,
        refetchPublicRanking,
        publicRankingError,
        publicRanking,
    } = usePublicRankingApi({
        enabled: Boolean(competitionId),
        competitionId: competitionId!,
    })

    const liveManager =
        stage && publicRanking
            ? new LiveManager(
                  publicRanking,
                  stage?.judges.individuals,
                  stage?.form
              )
            : undefined

    useEffect(() => {
        // Set up interval to fetch data every 5 seconds
        const intervalId = setInterval(() => {
            refetchPublicRanking()
        }, 5000)

        // Clean up interval on component unmount
        return () => clearInterval(intervalId)
    }, []) // Empty dependency array ensures that the effect runs only once after the initial render

    const getHeadersMap = (liveManager: LiveManager) => {
        let headersMap = {
            rank: API_MAP.rank,
            bib: API_MAP.bib,
            fis_code: API_MAP.fis_code,
            name: API_MAP.name,
        }

        for (
            let roundNumber = 1;
            roundNumber < liveManager.getRoundAmount() + 1;
            roundNumber++
        ) {
            let roundMap = {}
            for (
                let judgeNumber = 1;
                judgeNumber < liveManager.getJudgeNumber() + 1;
                judgeNumber++
            ) {
                // @ts-ignore
                roundMap[
                    `round_${roundNumber}_judge_${judgeNumber}_partial_score`
                ] = `R${roundNumber} J${judgeNumber}`
            }
            // @ts-ignore
            roundMap[`round_${roundNumber}_score`] =
                `R${roundNumber} ${API_MAP.score}`
            if (liveManager.stageFormat === "2z3") {
                // @ts-ignore
                roundMap[`round_${roundNumber}_trick_id`] =
                    `R${roundNumber} ${API_MAP.trick_id}`
            }

            Object.assign(headersMap, roundMap)
        }

        // @ts-ignore
        headersMap["final_score"] = API_MAP.final_score

        return headersMap
    }

    if (isPublicRankingLoading || isStageLoading) return <LoadingLayout />

    if (publicRankingError || stageError)
        return (
            <Grid container>
                <Typography variant={"h5"} align={"center"}>
                    Ranking is not available
                </Typography>
            </Grid>
        )

    return (
        <PrimaryLayout>
            <>
                {stage && liveManager && (
                    <Grid container>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <Paper
                                component={Stack}
                                direction="column"
                                justifyContent="center"
                                sx={{ boxShadow: "none" }}
                            >
                                <Typography variant={"h5"} align={"center"}>
                                    Ranking
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTable
                                tableId={`ranking-${competitionId}-page`}
                                rowId={"athlete_id"}
                                headersMap={getHeadersMap(liveManager)}
                                rows={liveManager.getSortedRanking()}
                                redHorizontal={
                                    stage.athletes_for_next_stage - 1
                                }
                                highlightedMap={{}}
                            />
                        </Grid>
                    </Grid>
                )}
            </>
        </PrimaryLayout>
    )
}
