import { useQuery } from "react-query"

import { BACKEND_URL } from "../../config"
import { prepareError } from "../utils"

interface Competition {
    id: string
    name: string
    rank: string
    sport: string
    category: string
    discipline: string
}

interface Event {
    id: string
    name: string
    competitions: Competition[]
}

interface usePublicEventsApiProps {
    enabled: boolean
    onSuccess?: (data: unknown) => void
    onError?: (err: unknown) => void
}

export default function usePublicEventsApi(props: usePublicEventsApiProps) {
    const {
        isLoading: isEventsLoading,
        error: eventsError,
        refetch: refetchEvents,
        data: events,
    } = useQuery<Event[]>(
        "events",
        () =>
            fetch(`${BACKEND_URL}/public/api/events/`).then((res) => {
                if (!res.ok) {
                    return prepareError(res)
                } else return res.json()
            }),
        {
            refetchOnWindowFocus: false,
            enabled: props.enabled,
            onSuccess: (data) => props.onSuccess && props.onSuccess(data),
            onError: (err) => {
                props.onError && props.onError(err)
            },
            retry: 0,
        }
    )

    return { isEventsLoading, eventsError, refetchEvents, events }
}
