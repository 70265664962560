import Cookies from "universal-cookie"

export default function useToken() {
    const COOKIE_NAME = "Token"
    const cookies = new Cookies()
    const getToken = (): string | undefined => {
        return cookies.get(COOKIE_NAME)
    }

    const setToken = (token: string): void => {
        cookies.set(COOKIE_NAME, token, {
            path: "/",
            maxAge: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).getTime(),
        })
    }

    const removeToken = () => cookies.remove(COOKIE_NAME)

    return { removeToken, getToken, setToken }
}
